import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyAaVCo1GRAIH0kVLSNi6lGhoseDFhU-TWY",
  // authDomain: "orbitmart-c5900.firebaseapp.com",
  // projectId: "orbitmart-c5900",
  // storageBucket: "orbitmart-c5900.appspot.com",
  // messagingSenderId: "349071815807",
  // appId: "1:349071815807:web:1850a89c07fd4fafaabb4a",
  // measurementId: "G-PCB95P3QET"


  apiKey: "AIzaSyCHOqapDLehS0TP6nmks_TErX07wcfIHUQ",

  authDomain: "orbitmart-dev.firebaseapp.com",

  projectId: "orbitmart-dev",

  storageBucket: "orbitmart-dev.appspot.com",

  messagingSenderId: "964201207187",

  appId: "1:964201207187:web:33dfc60ca5234a29cac466",

  measurementId: "G-ZPMK24L0YF"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

// const { REACT_APP_VAPID_KEY } = process.env;
const REACT_APP_VAPID_KEY = "BOtVaHZhIJSrTxLsnDzoKtb-Ru9tnGUCuJKFOIrLvcM_8O6FiEf_RNGB3HqxSmsfNW0uGGSY6gi-95KdQmViYdA"
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      // setTokenFound(true);
    } else {
      // setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
