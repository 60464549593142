import * as React from "react";
import {useState, useContext } from "react";
import { StateContext } from "./context/ContextProvider";
import { Button, TextField } from "@material-ui/core";
const VerifyMobile = (e) => {
  //debugger;
  const [mobile,setMobile] = useState('')
  const {verifymobile, setVerifyMobile} =useContext(StateContext)
  //debugger;  
  return (
      <div
        style={{
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <form >
          <h3 style={{textAlign: "center"}}>Verify your Mobile</h3>
          <TextField
            margin="normal"
            required
            fullWidth
            name="mobile"
            label="Enter your mobile number"
            type="number"
            id="mobile"
            onInput = {(e) =>{
              e.target.value = (e.target.value).toString().slice(0,10)
          }}
            autoComplete="current-mobile"
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
          <br/>
          <div style={{ whiteSpace: "nowrap" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ display: "inline-block" }}
            >
              Send OTP
            </Button >
          </div>
        </form>
        <br />
      </div>
  );
};

export default VerifyMobile;
