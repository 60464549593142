import React from 'react';
import { Link } from "react-router-dom";

import h1 from "./../../src/assets/images/h1.jpg";
import h2 from "./../../src/assets/images/h2.jpg";
import h3 from "./../../src/assets/images/h3.jpg";
import h4 from "./../../src/assets/images/h4.jpg";

function RestMarchant() {
  return (
    <div>
        <div className="mx-5 crd">
          <h4 style={{ marginTop: "10px" }}>Resturant's Marchant's List</h4>
          {/* <p className="p1">Add Popular Categories to weekly line up</p> */}
          <div className="container catbx">
            <div className="row pt-5 pb-4 mx-6">
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={h1 }/>
                <h4>Ram's Special</h4> <br/>
                {/* <p> upto 30% off</p> */}
                <Link to="/RamProduct">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={h2} />
                <h4>Hot Deal's</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/RamProduct">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={h3} />
                <h4>Fast Food Zone</h4><br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/RamProduct">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={h4} />
                <h4>Grill's</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/RamProduct">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default RestMarchant