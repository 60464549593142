import {useState, useContext} from 'react'
import '../myProfile.css'
import { StateContext } from "../../../context/ContextProvider";
import { useFormik } from "formik";
import { API } from "../../../config/api";
import axios from "axios";
import { showLoader } from "../../../compenent/helpers/loading";
import { showErrorMsg, showSuccessMsg } from "../../../compenent/helpers/message";
import * as Yup from "yup";

const Settings = () => {
    const { user, setUser } = useContext(StateContext);
    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
  const [profileMenus, setProfileMenus] = useState("Personal Information");
  const [isProfileAccessed, setIsProfileAccessed] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);

  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };

  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    }, 2000);
    return timeout;
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please enter First Name"),
    lastname: Yup.string().required("Please enter Last Name"),
    email: Yup.string()
      .required("Please enter Email")
      .email("Please enter valid Email ID"),
    gender: Yup.string()
      .required("Please select Gender")
      .test("test-gender", "Please select any one of gender", function (value) {
        if (value !== "MALE" && value != "FEMALE") {
          return false;
        }
        return true;
      }),
    mobile: Yup.string()
      .required("Please enter Mobile number")
      .matches(phoneRegex, "Please enter valid Mobile Number"),
  });

  const formik = useFormik({
    initialValues: {
      firstname: user.data.firstName,
      lastname: user.data.lastName,
      gender: user.data.gender,
      email: user.data.email,
      mobile: user.data.mobile,
    },
    validationSchema: validationSchema,
    onSubmit: async (userinfo) => {
      setLoading(true);
      const userdata = {
        firstName: userinfo.firstname,
        lastName: userinfo.lastname,
        email: userinfo.email,
        mobile: userinfo.mobile,
        gender: userinfo.gender,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-token": sessionStorage.getItem("token"),
        },
      };

      await axios
        .put(`${API}/auth/edit-profile`, userdata, config)
        .then(async (res) => {
          if (res.data.status == "SUCCESS") {
            await axios.get(`${API}/auth/get-profile`, config).then((res) => {
              setUser(res.data);
              setMsg("Profile updated succesfully");
              timeout = displaySuccessMsg(setSuccessMsg, timeout);
            });

            setLoading(false);
          }
        })
        .catch((err) => {
          // setMsg('Try again Later');
          // timeout = displayErrMsg(setErrMsg, timeout);
          // setLoading(false)
        });
    },
  });

  return (
    <div className="mb-4 bs">
    {" "}
    <div>
      <h5 className="mb-auto headerProfile">Settings</h5>
    </div>
    <div className="  px-2 pt-4">
      <form
        style={{
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        {successMsg && showSuccessMsg(msg, successMsg)}
        {loading && showLoader()}
        {errMsg && showErrorMsg(msg, errMsg)}
        <div
          className="row borderremove"
          style={{ marginLeft: "10px" }}
        >
          <div>
            <input type="checkbox" /> <span>OTP in Email</span>
          </div>

          <div style={{ margin: "20px 0" }}>
            <input type="checkbox" /> <span>OTP in Sms</span>
          </div>

          <div>
            <input type="checkbox" /> <span>OTP in WhatsApp</span>
          </div>
          <br />
          <br />

          <div className="row borderremove">
            {/* <Link to="/PaymentMethod"> */}

            <button
              type="submit"
              className="btn updatebtn col-sm-6"
              style={{ marginTop: "10%" }}
            >
              UPDATE
            </button>
            {/* <div className="col-sm-6"></div> */}
          </div>
        </div>
      </form>
    </div>
  </div>
  )
}

export default Settings