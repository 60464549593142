import React from "react";
import Button from "@material-ui/core/Button";

const ViewOrderDetails = () => {
  return (
    <>
      <div className="">
        <div className="row morder_btn">
          <div className="col-md-4">
            <Button variant="contained">Cancel Order</Button>
          </div>
          <div className="col-md-4">
            <p className="" style={{ marginTop: "1rem", textAlign: "center" }}>
              Order Details
            </p>
          </div>
          <div className="col-md-4">
            <Button variant="contained">Track Order</Button>
          </div>
        </div>
      </div>
      <div className="row m-auto">
        <div className="">
          <div className=" card-header bg-white media flex-sm-row flex-column-reverse justify-content-between  ">
            <div className="col my-auto">
              <h4 className="mb-0">Thanks for your Order, Mohit !</h4>
            </div>
          </div>
          <div>
            {[...Array(3)].map((option, id) => (
              <div className="media border-top border-bottom">
                <div className="media-body my-auto text-right">
                  <div className="row  my-auto flex-column flex-md-row">
                    <div className="sq align-self-center col my-auto ">
                      <img
                        className="img-fluid  my-auto align-self-center mr-2 mr-md-4 pl-0 p-0 m-0"
                        src="https://i.imgur.com/RJOW4BL.jpg"
                        alt="ok"
                        width="135"
                        height="135"
                      />{" "}
                    </div>
                    <div className="col my-auto">
                      {" "}
                      <h6 className="mb-0"> Jack Jacs</h6>{" "}
                    </div>
                    <div className="col-auto my-auto">
                      {" "}
                      <small>Golden Rim </small>
                    </div>
                    <div className="col my-auto">
                      {" "}
                      <small>Size : M</small>
                    </div>
                    <div className="col my-auto">
                      {" "}
                      <small>Qty : 1</small>
                    </div>
                    <div className="col my-auto">
                      <h6 className="mb-0">&#8377;3,600.00</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="">
          <div className="d-flex justify-content-around">
            <div>
              <div className="text-center fw-bold"> Order Details</div>
              <div className="col">
                <p className="mb-1"> Invoice Number : 788152</p>
                <p className="mb-1">Invoice Date : 22 Dec,2019</p>
                <p className="mb-1">Recepits Voucher:18KU-62IIK</p>
              </div>
            </div>
            <div>
              <div className="text-center fw-bold"> Payment Details</div>
              <div className="col">
                <p className="mb-1"> Invoice Number : 788152</p>
                <p className="mb-1">Invoice Date : 22 Dec,2019</p>
                <p className="mb-1">Recepits Voucher:18KU-62IIK</p>
              </div>
            </div>
            <div>
              <div className="text-center fw-bold"> Total Details</div>
              <div className="row justify-content-between">
                <div className="flex-sm-col text-right col">
                  {" "}
                  <p className="mb-1">
                    <b>Total</b>
                  </p>{" "}
                </div>
                <div className="flex-sm-col col-auto">
                  {" "}
                  <p className="mb-1">&#8377;4,835</p>{" "}
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="flex-sm-col text-right col">
                  <p className="mb-1">
                    {" "}
                    <b>Discount</b>
                  </p>{" "}
                </div>
                <div className="flex-sm-col col-auto">
                  <p className="mb-1">&#8377;150</p>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="flex-sm-col text-right col">
                  <p className="mb-1">
                    <b>Delivery Charges</b>
                  </p>
                </div>
                <div className="flex-sm-col col-auto">
                  <p className="mb-1">Free</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrderDetails;
