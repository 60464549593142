import {useState} from 'react'
import "./deleteAccount.css"

const DeleteAccount = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showLoginForm, setShowLoginForm] = useState(false);

    const handleDeleteClick = () => {
        setShowLoginForm(true); 
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Username:', username);
        console.log('Password:', password);
        setShowLoginForm(false); 
      };
    
  return (
    <div>
              <div className="mb-4 bs" style={{marginTop: '7rem'}}>
                {" "}
                {/* <div className="card mb-4 bs"> */}
                <div
                // className="card-header py-3 "
                // style={{ backgroundColor: "#003C7E" }}
                >
                  <h5 className="mb-auto headerProfile">Delete Account</h5>
                </div>
                <div className="  px-2 pt-4">
                  {!showLoginForm && (
                  <div className="accountDelete-bar">
                    <div className="inner-accountDelete-bar">
                      <h2>Deleting account is a permanent action</h2>
                      <br />
                      <p>
                        Please be advised that the deletion of your account is a
                        permanent action. Once your account is deleted, you will
                        lose orbitmart data including order history & it will no
                        longer be accessible and cannot be restored under any
                        circumstances.
                      </p>
                    </div>
                  
                    <div className="leaving-us">
                       <p>Please tell us why you’re leaving us</p>
                       <textarea className="textAra" rows="5" cols="50" placeholder="Your feedback will help us improve orbitmart."></textarea>
                    </div>
                    <button onClick={handleDeleteClick} className="delete-btn">Delete Account</button>
                  </div> )}
                  {/* login page */}
                  {showLoginForm && (
                  <div className="login-bar-delete-account">
                    <div className="inner-login-bar-delete-account">
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <label htmlFor="username" style={{margin: '5px 0'}}>Username</label>
                            <input
                              type="text"
                              id="username"
                              value={username}
                              placeholder="Enter Username"
                              onChange={(e) => setUsername(e.target.value)}
                              required
                              className="inpus"
                            />
                          </div>
                          <div style={{margin: '20px 0'}}>
                            <label htmlFor="password" style={{margin: '5px 0'}}>Password</label>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              placeholder="Enter Password"
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              className="inpus"
                            />
                          </div>
                          <button type="submit" className="delete-btn">
                            Delete Account
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
    </div>
  )
}

export default DeleteAccount