import React from 'react'
import { useNavigate} from "react-router-dom";
const PageNotFound = () => {
    const navigate=useNavigate()
  return (
    <div className="mx-auto ms-3 my-5 crd bg-white rounded ">
    <div className="px-5 pb-5 ">
      <div className=" mx-auto pb-3 w-25 h-25 ">
      <div className="text-center  ">
      <img src="/assets/images/orbitlog.png" alt="logo" className='w-25 h-25 bg-navy'/>
      <h5 style={{ marginTop: "10px" }}>We're sorry</h5>
<h6>Unfortunately the page you are looking for has been moved or deleted</h6>
     
      
        <div className="row ">
       
    
    
          <img src="https://1.bp.blogspot.com/-W_8l-L7BARo/Xs0wlcD8GcI/AAAAAAAAJhQ/H5ztSXUAVYIKy2cEynjAOMd1M9qicizcgCLcBGAsYHQ/s1600/404.png" alt="merchant not found"  style={{margin:"auto"}} />
        </div>
        <button className="btn-sm btn-warning my-5" type="submit" onClick={()=>navigate('/')}>
                        Go to Homepage
                      </button>
      </div>
      </div>
    </div>
    <div></div>
  </div>
  )
}

export default PageNotFound