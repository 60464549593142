export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};
export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const addToCart = async (product) => {
  // if cart already exists in local storage, use it, otherwise set to empty array
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];

  // check if duplicates
  const duplicates = cart.filter((cartItem) => cartItem._id === product._id);

  // if no duplicates, proceed
  if (duplicates.length === 0) {
    // prep equipment data
    const productToAdd = {
      ...product,
      count: 1,
      firmName: localStorage.getItem("firmName"),
    };

    // add equipment data to cart
    cart.push(productToAdd);

    // add cart to local storage
    localStorage.setItem("cart", JSON.stringify(cart));
  }
};

export const clearSessionOnCheckout = () => {
  window.sessionStorage.removeItem("pincode");
  window.sessionStorage.removeItem("area");
  window.sessionStorage.removeItem("location");
  deleteLocalStorage("cart");
};

export const deleteFromCart = async (product) => {
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];

  const updatedCart = cart.filter((cartItem) => cartItem._id !== product._id);

  localStorage.setItem("cart", JSON.stringify(updatedCart));
};

export const getCart = () => {
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  return cart;
};
