import React from 'react';
import {Link} from "react-router-dom";



import curd from "./../../src/assets/images/curd.jpg";
import butter from "./../../src/assets/images/butter.jpg";
import mozzarella from "./../../src/assets/images/mozzarella.jpg";



function AmulProducts() {
  return (
    <div>
        <section className="procrdpg">
        <div className="row ">
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={mozzarella} alt="Mozzarella" className="img-fruit" />
              <h3>Mozzarella Cheese</h3>

              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">500</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>

                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={curd} alt="Curd" className="img-fruit" />
              <h3>Curd</h3>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">200</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={butter} alt="Butter" className="img-fruit" />
              <h3>Butter</h3>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">220</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AmulProducts