import React,{useContext,useState} from "react";
import Draggable from 'react-draggable';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { FormGroup } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { useNavigate,Link as ALink } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import { useFormik } from "formik";
import { StateContext } from "../context/ContextProvider";
// import { API } from "../config/api"
import { API } from "../config/api"
import axios from "axios"
import { showErrorMsg } from "./helpers/message";
const phoneRegex = /^\+([0-9]{1,3})*\.([0-9]{5,16})$/;
const MobilePopup = (props) => {
 
  const {currentPage,setCurrentPage,setIsOpen,setRegisterInfo}=useContext(StateContext)
  const [errMsg,setErrMsg]=useState(false)
  const [msg,setMsg]=useState("")
         const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .required("Please enter valid Mobile number")
      .matches(phoneRegex,"Please enter valid Mobile number"),
  
  });
  const formik = useFormik({
    initialValues: { mobile: "" },
    validationSchema: validationSchema,
    onSubmit: async (user) => {
      resendOTP(user.mobile)

      }
        
      
    });


    var timeout;
    const displayErrMsg=(setErrMsg, timeout)=> {
      setErrMsg(true);
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setErrMsg(false);
      },2000);
      return timeout;
    }
    const resendOTP = async (mobile) => {
      await axios.get(`${API}/auth/resend/otp/${mobile}`).then((res) => {
        if (res.data.data == undefined) {
          setMsg("You are not registered with us.Please sign up");
          

          // setMsg(res.data.message);
          timeout = displayErrMsg(setErrMsg, timeout);
         
        } else {
          setRegisterInfo({ otp: res.data.data, mobile: mobile });
          setCurrentPage(4);
        }
      });
    };
  const showErrorMessage = (msg) => {
    return (
      <div class="alert alert-danger" role="alert">
   {msg}
    </div>
    )
  }
 
  return (
    <div className="popup-box">

{errMsg && showErrorMsg(msg, errMsg)}
       <Draggable>
     <div>
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>
          x
        </span>
        <div className=" d-flex w-100">
          <Grid container component="main">
            <CssBaseline />
            <Grid
              item
              sm={4}
              component={Paper}
              elevation={6}
              sx={{ backgroundColor: "#003C7E", color: "white" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ color: "white", pb: 2 }}
                >
                  Login
                </Typography>
                <Typography component="p" variant="body2">
                  
                  Sign up with your mobile number to get started
                </Typography>
              </Box>
              <center>
                <img
                  src={`https://remodelersuniversity.com/wp-content/uploads/2019/07/welcome-back-account-icon-450x450.png`}
                  className="w-75 mb-3"
                  alt={"welcome image"}
                  loading="lazy"
                />
              </center>
            </Grid>

            <Grid item sm={8} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                   
                }}
              >
        
                  
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <TextField
                    variant="standard"
                    id="mobile"
                    name="mobile"
                    type="text"
                    label="Enter Mobile number"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mobile && formik.touched.mobile}
                    helperText={
                      formik.errors.mobile && formik.touched.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                   
                     autoFocus
                    
               
                  />
                  <br />

              
                   
                    
                  
                  <p className="policy">
                    By continuing, you agree to OrbitMart's{" "}
                   
                    <ALink className="lk" to="/termsandcondition" onClick={()=>setIsOpen(false)} >
                    Terms of Use 
            </ALink>{" "}
                   
                    and{" "}
                    {/* <span className="lk" onClick={(e)=>handleNewTab(e)}>Privacy Policy</span> */}
                    {/* <Link onClick={openPrivacy}  className="lk">
                      Privacy Policy
                      
                    </Link> */}
                    <ALink className="lk" to="/privacypolicy" target="_blank" onClick={()=>setIsOpen(false)} >
              Privacy Policy
            </ALink>
                    
                    {/* <a rel="noopener noreferrer" href="/privacypolicy" className="lk" target="_blank">Privacy Policy</a> */}
                    .

                  </p>
                  <Box sx={{ mt: 2 }}>
                    <div className="card ">
                      <button
                        className="btn-sm btn-warning "
                        type="submit"
                      >
                        Continue
                      </button>
                     
                      <h6 className="text-center pt-3 text-muted">OR</h6>
                      <div className="btn-sm text-center txt-bl  mt-1 " style={{  cursor:'pointer'}} onClick={(e)=>setCurrentPage(0)}>
                        Existing User? Login
                      </div>
                    </div>
                  </Box>

                
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>{" "}
      </div>
      </div>
      </Draggable>
    </div>
  );
};

export default MobilePopup;
