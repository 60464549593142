import React from "react";
import { Link } from "react-router-dom";

import urea from "./../../src/assets/images/urea.jpg";
import Formalin from "./../../src/assets/images/Formalin.jpg";
import bavistin from "./../../src/assets/images/bavistin.jpg";

function SundarProducts() {
  return (
    <div>
      <section className="procrdpg">
        <div className="row ">
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={urea} alt="Urea" className="img-fruit" />
              <h3>Urea</h3>

              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">500</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>

                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={Formalin} alt="Formalin" className="img-fruit" />
              <h3>Formalin</h3>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">200</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={bavistin} alt="Bavistin" className="img-fruit" />
              <h3>Bavistin</h3>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">220</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SundarProducts;
