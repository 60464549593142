import React, { useContext } from 'react'
import axios from "axios";
// import MerchantType from "./MerchantType";
import { StateContext } from '../../context/ContextProvider';
import noimage from "../../assets/images/noimage.jpg"
import { API } from '../../config/api';
import { useNavigate } from 'react-router-dom';
import './domainType.css';
const DomainType = () => {
    // const {domainTypeL, setDomainTypeL,setMerchantList,setDomainTypeN}= useContext(StateContext)
    const {domainTypeL, setMerchantList,setDomainTypeN}= useContext(StateContext)

    const navigate = useNavigate();
    //Fetch dommain type data from API
  
    const getMerchantById = async (id, domainType) => {
      setDomainTypeN(domainType);
      await axios
        .get(`${API}/merchant/Type/bymerchantTypeId/${id}`)
        .then((res) => setMerchantList(res.data.data));
        
      navigate("/merchantList");
    };
  return (
    
    <div className="mx-auto ms-3 crd bg-white rounded ">
    <div className="px-5 pb-5 ">
      <div className="d-flex bd-highlight ">
        <div className=" flex-grow-1 bd-highlight pb-4">
          <h4 style={{ marginTop: "10px" }}>Marchant Type's</h4>
        </div>
       </div>
        <div className=" text-center ">
        <div className="row left-border pruduc-cart">
     
        {domainTypeL.map((domain, index) => (
          <div className="product-container single-cart" key={index}>
          <div
            className=" tody card bs h-100 bg-secondary merchant-cart"
            key={index}
            onClick={() => {
              getMerchantById(domain._id, domain.domainType);
            }}
          >
            <img
              src={domain.image?domain.image:noimage}
              alt={domain.domainType}
              className="card-img-top  p-1 w-100"
              style={{height:"200px"}}
            />

            <h6 className="text-center pt-4 pb-4">{domain.domainType}</h6>
          </div>
          
          </div>
          
          // <Domain key={index} id={_id} domainType={domainType} src={DT[0].image} />
        ))}
     </div>
    </div>
  </div>
  </div>

    
  )
}

export default DomainType