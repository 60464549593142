import React, { useContext, useRef, useState, Fragment } from "react";
import "./Drawer.css";
import { Link, useNavigate } from "react-router-dom";
import { BiCategoryAlt } from "react-icons/bi";
import { RiContactsFill } from "react-icons/ri";
import { BsChevronDown } from "react-icons/bs";
import { RiListUnordered } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";
import { IoIosWallet } from "react-icons/io";
import { FaTag } from "react-icons/fa";
// import { BiHelpCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  // ContextProvider,
   StateContext } from "../../context/ContextProvider";

const DrawerContents = () => {
  const [dropdown, setDropdown] = useState(false);
  const { user, setUser, setAuth, auth } = useContext(StateContext);
  const [actv, isACTV] = useState("");
  const handleClick = (type) => {
    isACTV(type);
  };
  const navigate = useNavigate();

  const handleLogout = (e) => {
    //  window.localStorage.setItem("userId","");
    //  window.localStorage.setItem("token","");
    e.preventDefault();
    setUser([]);
    setAuth(false);
    navigate("/");
  };

  return (
    <div className="DrawerContents__Container">
      <div className="DrawerContent__Header">
        <div className="Drawer_Inner_Content">
          <h5 className="DrawerHeading_Welcome">WELCOME TO</h5>
          {!auth && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src="/assets/images/orbitlog.png"
                alt="logo"
                className="Drawer_logo"
              />
            </div>
          )}
          {auth && (
            <div className="Drawer_User_Data_Container">
              <div className="Drawer_User_Img">
                {auth && user?.data?.avatar.includes("") ? (
                  <img src="./assets/img/avatar-place.png" alt="" />
                ) : (
                  <img src={user?.data?.avatar} alt="" />
                )}
              </div>
              {auth ? (
                <div className="Drawer_User_Data">
                  <h5>{"Hey " + user?.data?.firstName + user?.data?.lastName}</h5>
                  <p>{user?.data?.email}</p>
                  <p>{user?.data?.mobile}</p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <hr className="Drawer_Divider_line" />
      <div className="DrawerContent__InnerHeader">
        <Link to="/" className="DrawerContent__Category">
         <div className={`DrawerContent__Category-content ${actv === "Home" ? "DrawerContent__Category-indivisual-sec" : ''}`}
            onClick={() => handleClick("Home")}>
           <i
            className="fa fa-solid fa-home text-white i"
            style={{ fontSize: "18px" }}
          />
          <span style={{ color: "white" }}> Home</span></div>
        </Link>
        {auth && (
          <Link to="/myorder" className="DrawerContent__Category">
            <div className={`DrawerContent__Category-content ${actv === "Order" ? "DrawerContent__Category-indivisual-sec" : ''}`}
            onClick={() => handleClick("Order")}>
            <RiListUnordered className="i" style={{ fontSize: "18px" }} />
            <span style={{ color: "white" }}> My Order</span>
            </div>
          </Link>
        )}
        <Link to="/shoppingcart" className="DrawerContent__Category">
        <div className={`DrawerContent__Category-content ${actv === "Cart" ? "DrawerContent__Category-indivisual-sec" : ''}`}
            onClick={() => handleClick("Cart")}>
        <FaShoppingCart className="i" style={{ fontSize: "18px" }} />
        <span style={{ color: "white" }}> My Cart</span>
        </div>
        
        </Link>
        {auth && (
          <Link to="/" className="DrawerContent__Category">
            <div className={`DrawerContent__Category-content ${actv === "Wallet" ? "DrawerContent__Category-indivisual-sec" : ''}`}
            onClick={() => handleClick("Wallet")}>
            <IoIosWallet className="i" style={{ fontSize: "18px" }} />
            <span style={{ color: "white" }}> My Wallet</span>
            </div>
          </Link>
        )}
        {auth && (
          <Link to="/" className="DrawerContent__Category">
            <div className={` DrawerContent__Category-content ${actv === "Voucher" ? "DrawerContent__Category-indivisual-sec" : ''}`}
             onClick={() => handleClick("Voucher")}>
            <FaTag className="i" style={{ fontSize: "18px" }} />
            <span style={{ color: "white" }}> My Voucher</span>
            </div>
           
          </Link>
        )}
        {auth && (
          <Link to="/myprofile" className="DrawerContent__Category">
            <div className={`DrawerContent__Category-content ${actv === "Profile" ? "DrawerContent__Category-indivisual-sec" : ''}`}
            onClick={() => handleClick("Profile")}>
            <CgProfile className="i" style={{ fontSize: "18px" }} />
            {/* <i className="fa fa-solid fa-order text-white " style={{ fontSize: '18px' }} /> */}
            <span style={{ color: "white" }}> Profile</span>
            </div>
           
          </Link>
        )}
        {auth && (
          <Link to="/notification" className="DrawerContent__Category">
            <div className={`DrawerContent__Category-content ${actv === "Notification" ? "DrawerContent__Category-indivisual-sec" : ''}`}
            onClick={() => handleClick("Notification")}>
            <i
              className="fa fa-solid fa-bell text-white i"
              style={{ fontSize: "18px" }}
            />
            <span style={{ color: "white" }}> Notification</span>
            </div>
           
          </Link>
        )}
        <Link to="/category" className="DrawerContent__Category">
          <div className={`DrawerContent__Category-content ${actv === "Category" ? "DrawerContent__Category-indivisual-sec" : ''}`}
          onClick={() => handleClick("Category")}>
            <BiCategoryAlt className="i" style={{ color: "#fff", fontSize: "18px" }} />
            <span style={{ color: "white" }}> Category</span>
          </div>
        </Link>
        <div
          className="DrawerContent__HeaderContact"
          onClick={() => setDropdown(!dropdown)}
        >
          <div className={`DrawerContent__HeaderContactList DrawerContent__Category-content ${actv === "Contacts" ? "DrawerContent__Category-indivisual-sec" : ''}`}
          onClick={() => handleClick("Contacts")}
          >
            <RiContactsFill className="i" />
            Contacts
          </div>

          <BsChevronDown
            style={{ color: "white" }}
            className={dropdown ? "rotate" : ""}
          />
        </div>
        {dropdown && (
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
            className={dropdown && "slideInDown"}
          >
            <Link className="DrawerContent__Links" to="/distributercontact">
              Distributor Contact
            </Link>
            <Link className="DrawerContent__Links" to="/companycontact">
              Company Contact
            </Link>
            <Link className="DrawerContent__Links" to="/franchise">
              Apply for Franchise
            </Link>
          </div>
        )}
      </div>
      {auth && (
        <Fragment>
          <hr className="Drawer_Divider_line" />
          <div>
            <p className="Drawer_Other_Category">Other</p>
            <div
              className="Drawer_Logout icons  p-2"
              onClick={(e) => handleLogout(e)}
            >
              <i
                className="fa fa-sign-out text-right text-white fa-md"
                aria-hidden="true"
              >
                <b className="ps-1">Logout</b>
              </i>
            </div>
          </div>
        </Fragment>
      )}
      {auth && (
        <div className="Drawer_footer_logo">
          <img
            src="/assets/images/orbitlog.png"
            alt=""
            className="Drawer_logo"
          />
        </div>
      )}
    </div>
  );
};
const Drawer = ({ isOpen, setIsOpenDrawer }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpenDrawer(false));
  return (
    <div
      className={`Drawer_Container ${isOpen ? "slideInRight" : "slideOutRight"
        }`}
      ref={ref}
    >
      <DrawerContents />
    </div>
  );
};

export default Drawer;
