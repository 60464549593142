import React, { useContext, useState } from "react";
import Draggable from "react-draggable";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import { useFormik } from "formik";
import { StateContext } from "../context/ContextProvider";
import { API } from "../config/api";
import axios from "axios";
import { showLoader } from "../compenent/helpers/loading";
import { showErrorMsg } from "./helpers/message";
import { useNavigate, Link as ALink } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import { setItemToLocalStorage } from "../utils/localStorageActions";

//const phoneRegex = /^\+([0-9]{1,3})*\.([0-9]{5,16})$/;

const LoginPopup = (props) => {
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 1000);
    return timeout;
  };

  const navigate = useNavigate();

  const {
    setCurrentPage,
    isOpen,
    setIsOpen,
    setAuth,
    setUser,
    setRegisterInfo,
    redirect,
    setRedirect,
    setMyOrder,
  } = useContext(StateContext);
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Please enter valid Email ID/Mobile number")
      .test(
        "test-name",
        "Please enter valid Email ID/Mobile number",
        function (value) {
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);

          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
      ),
    password: Yup.string()
      .required("Please enter Password")
      .min(6, "Password must be at least 6 characters"),
  });
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (user) => {
      let data = {
        username: user.email,
        password: user.password,
        role: "USER",
      };
      // {emailRegex.test(user.email)?
      //    data= {
      //     username: user.email,
      //     password: user.password,
      //     role: "USER",
      //   }
      //   :
      //   data= {
      //     username: user.mobile,
      //     password: user.password,
      //     role: "USER",
      //   }
      // }
      setErrMsg(false);
      setLoading(true);
      const config = { headers: { "Content-Type": "application/json" } };

      axios
        .post(`${API}/auth/login`, data, config)
        .then(async (res) => {
          if (res.data.status === "ERROR") {
            setMsg(res.data.message);
            timeout = displayErrMsg(setErrMsg, timeout);
            setLoading(false);
          } else if (res.data.status === "SUCCESS") {
            window.localStorage.setItem("userId", res?.data?.data?._id);
            setItemToLocalStorage("token", res?.data?.data?.token);
            sessionStorage.setItem("token", res?.data?.data?.token)

            let config = {
              headers: {
                "Content-Type": "application/json",
                "x-token": res?.data?.data?.token,
              },
            };

            await axios
              .get(`${API}/order/user/${res?.data?.data?._id}`)
              .then((res) => setMyOrder(res.data.data.reverse()));
            await axios
              .put(
                `${API}/auth/edit-profile`,
                { WEB_TOKEN: sessionStorage.getItem("FCM_TOKEN") },
                config
              )
              .then(async (res) => {
                window.localStorage.setItem("user", JSON.stringify(res?.data));
                setUser(res.data);
                setAuth(true);
                setIsOpen(!isOpen);
              });
            setLoading(false);
            if (redirect) {
              setRedirect(false);
              navigate("/shipping");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.status === "ERROR") {
            setMsg(err.response.data.message);
            setErrMsg(true);
            timeout = displayErrMsg(setErrMsg, timeout);
          }
        });
    },
  });
  const checkMobileOrEmail = () => {
    let isValidEmail = emailRegex.test(formik.values.email);
    let isValidPhone = phoneRegex.test(formik.values.email);

    if (isValidEmail) {
      formik.setErrors({});
      setCurrentPage(3);
    } else if (isValidPhone) {
      formik.setErrors({});
      resendOTP(formik.values.email); //mobile
    }
  };
  const resendOTP = async (mobile) => {
    await axios.get(`${API}/auth/resend/otp/${mobile}`).then((res) => {
      if (res.data.data === undefined) {
        setMsg("You are not registered with us.Please sign up");
        setErrMsg(true);
      } else {
        setRegisterInfo({ otp: res.data.data, mobile: mobile });
        setCurrentPage(4);
      }
    });
  };
  const forgotPwd = () => {
    formik.setErrors({ email: true });
    checkMobileOrEmail();
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="popup-box">
      {errMsg && showErrorMsg(msg, errMsg)}

      <Draggable>
        <div>
          <div className="box">
            <span className="close-icon" onClick={props.handleClose}>
              x
            </span>

            <div className=" d-flex w-100">
              <Grid container component="main">
                <CssBaseline />
                <Grid
                  item
                  sm={4}
                  component={Paper}
                  elevation={6}
                  sx={{ backgroundColor: "#003C7E", color: "white" }}
                >
                  <Box
                    sx={{
                      my: 8,
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{ color: "white", pb: 2 }}
                    >
                      Login
                    </Typography>
                    <Typography component="p" variant="body2">
                      Get access to your Orders, Wishlist and Recommendations
                    </Typography>
                  </Box>
                  <center>
                    <img
                      src={`https://remodelersuniversity.com/wp-content/uploads/2019/07/welcome-back-account-icon-450x450.png`}
                      className="w-75 mb-3"
                      alt={"welcome"}
                      loading="lazy"
                    />
                  </center>
                </Grid>

                <Grid item sm={8} component={Paper} elevation={6} square>
                  <Box
                    sx={{
                      my: 8,
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {loading && showLoader()}
                    <Box component="form" onSubmit={formik.handleSubmit}>
                      <TextField
                        variant="standard"
                        id="email"
                        name="email"
                        type="text"
                        label="Enter Email/Mobile number"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email && formik.touched.email}
                        helperText={
                          formik.errors.email && formik.touched.email
                            ? formik.errors.email
                            : ""
                        }
                        autoFocus
                        inputProps={{
                          autoComplete: "new-email",
                          form: {
                            autoComplete: "off",
                          },
                          style: { textTransform: "lowercase" },
                        }}

                        // sx={{textTransform: 'lowercase' }}
                        // InputProps={{ inputProps: { style: { color: '#fff' }}}}
                        // sx={{textTransform:"lowercase"}}
                        // style={{textTransform:"lowercase",backgroundColor:"white"}}
                      />
                      <br />

                      <TextField
                        variant="standard"
                        id="password"
                        name="password"
                        label="Enter Password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.password && formik.touched.password
                        }
                        helperText={
                          formik.errors.password && formik.touched.password
                            ? formik.errors.password
                            : ""
                        }
                        InputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Visibility />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: { textTransform: "lowercase" },
                        }}
                      />

                      <p className="policy">
                        By continuing, you agree to OrbitMart's{" "}
                        <ALink
                          className="lk"
                          to="/termsandcondition"
                          onClick={() => setIsOpen(false)}
                          style={{textDecoration: 'underline'}}
                        >
                          Terms of Use
                        </ALink>{" "}
                        and{" "}
                        {/* <span className="lk" onClick={(e)=>handleNewTab(e)}>Privacy Policy</span> */}
                        {/* <Link onClick={openPrivacy}  className="lk">
                      Privacy Policy
                      
                    </Link> */}
                        <ALink
                          className="lk"
                          to="/privacypolicy"
                          target="_blank"
                          onClick={() => setIsOpen(false)}
                          style={{textDecoration: 'underline'}}
                        >
                          Privacy Policy
                        </ALink>
                        {/* <a rel="noopener noreferrer" href="/privacypolicy" className="lk" target="_blank">Privacy Policy</a> */}
                        .
                      </p>
                      <Box sx={{ mt: 2 }}>
                        <div className="card">
                          <button className="btn-sm btn-warning" type="submit" style={{width: '100%'}}>
                            Login
                          </button>

                          {/* <h6 className="text-center pt-3 text-muted">OR</h6>
                      <div className="btn-sm text-center txt-bl  mt-1" onClick={(e)=>checkMobile()}>
                        Request OTPonClick={()=>setCurrentPage(0)}
                      </div> */}
                        </div>
                      </Box>

                      <Grid container>
                        <Grid item xs>
                          <Link
                            variant="body2"
                            sx={{ mx: "auto" }}
                            onClick={forgotPwd}
                            style={{ cursor: "pointer" }}
                          >
                            Forgot password?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            variant="body2"
                            onClick={() => setCurrentPage(1)}
                            sx={{ cursor: "pointer" }}
                          >
                            {"Create an account? "}
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default LoginPopup;
