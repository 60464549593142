import React from 'react';
import {Link} from 'react-router-dom';



import crocin from "./../../src/assets/images/crocin.jpg";
import zincovit from "./../../src/assets/images/zincovit.jpg";
import glucose from "./../../src/assets/images/glucose.jpg";




function GovindProducts() {
  return (
    <div>
        <section className="procrdpg">
        <div className="row ">
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={crocin} alt="crocin" className="img-fruit" />
              <h3>Crocin</h3>

              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">120</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>

                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src={zincovit}
                alt="zincovit"
                className="img-fruit"
              />
              <h3>Zincovit</h3>              
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">200</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src={glucose}
                alt="glucose"
                className="img-fruit"
              />
              <h3>Glucon-D</h3>                           
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true"></i>
                    <span className="amount">120</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <Link to="/merchantprodetails">
                  <button className="ip-add-cart">Add to cart</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
       
          
      </section>
    </div>
  )
}

export default GovindProducts