
import React, { useContext,useState} from "react";
import { StateContext } from "../context/ContextProvider";
import axios from "axios";
import { API } from "../config/api";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import NoProduct from "./NoProduct";
import noimage from "../assets/images/noimage.jpg"
import { addToCart, deleteFromCart, deleteLocalStorage, getCart } from "./helpers/localstorage";
import { showErrorMsg,showSuccessMsg } from "./helpers/message";
import { showLoader } from "../compenent/helpers/loading";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius:"10px",
  boxShadow: 24,
  p: 4,
};
const DisplayCategories = () => {

    const {categoryL,categoryId,setCategoryId,categoryProduct,setCategoryProduct} = useContext(StateContext);
    var timeout;
    const displayErrMsg=(setErrMsg, timeout)=> {
      setErrMsg(true);
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setErrMsg(false);
      },1000);
      return timeout;
    }
    const displaySuccessMsg=(setSuccessMsg, timeout)=> {
      setSuccessMsg(true);
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setSuccessMsg(false);
      },1000);
      return timeout;
    }
    const [errMsg, setErrMsg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [msg,setMsg]=useState('')
    const [popup,setPopup]=useState(false)
    const [merchantA,setMerchantA]=useState('')
    const [merchantB,setMerchantB]=useState('')
    const [isChangeCproduct,setIsChangeProduct]=useState([])
    const handleOptions=async(id)=>{
      setCategoryId(id)
      await axios
      .get(`${API}/product/categoryId/${id}`)
      .then((res) => setCategoryProduct(res.data.data));

     }
     const {cart,setCart}=useContext(StateContext)
     const handleAddToCart = (product) => {
      // console.log('madhu product',product)
    
      // console.log('madhu cart',cart)
      if(merchantExist(product.merchantId))
      {
        addToCart(product);
        setCart(getCart());
        setSuccessMsg(true)
        setMsg("Product Added SuccessFully")
          
        timeout = displaySuccessMsg(setSuccessMsg, timeout);
      }
      else
      {
        console.log(product)
      setMerchantA(cart.filter((p) =>  p.merchantId !== product?.merchantId )[0].firmName)
      setMerchantB('category merchant')
      setIsChangeProduct(product)
      setPopup(true)
      }
        //const merchantExist = cart.find((p) =>  p.merchantId === product?.merchantId )
    
      
      };
    
      const merchantExist=(merId)=>{
        return cart.every(function (p){
          return p.merchantId==merId
        })
      }
      
    const handleQtyChange = (e, product,action) => {

      const cart = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : [];
    
        cart.forEach((cartItem) => {
          if (cartItem._id === product._id){
            if(action==='+'){//increment
              cartItem.count+=1
            } 
            else//decrement
            {
                if(cartItem.count==1)
                {
                  deleteFromCart(product);setCart(getCart())
                  return false;     
                }
                else
                {
                  cartItem.count-=1
                }
              }
          }
        });
    
       localStorage.setItem("cart", JSON.stringify(cart));
       setCart(getCart())
       setSuccessMsg(true)
       setMsg("Product updated SuccessFully")
         
       timeout = displaySuccessMsg(setSuccessMsg, timeout);

    };
    console.log(categoryProduct)

     const handleResetCart=()=>{
      deleteLocalStorage('cart')
      addToCart(isChangeCproduct);
      setCart(getCart())
      setPopup(false)
    }
    const cartResetPopup=()=>{
      return <Modal
      open={popup}
      // onClose={()=>setPopup(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h6" >
          Replace cart item?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 ,textTransform:'lowercase'}}>
        Your cart contains dishes from <b>{merchantA}</b>.Do you want to discard the selection and add dishes from <b>{merchantB}</b>?
   
        </Typography>
        <Box sx={{px:5,mx:5,pt:2,display:'flex'}} >
        <Button onClick={()=>setPopup(false)} variant="contained" >NO</Button>
        <Button onClick={handleResetCart} variant="contained">YES</Button>
      
        </Box>
        </Box>
    </Modal>
    }


  return (
  
    <div className="row" style={{marginTop: '80px'}}>
    <div className="col-lg-2 pt-5">
        <div className="scrolleffect d-flex flex-column bd-highlight mb-3 overflow-auto  d-inline-block scrollbar scrollbar-lady-lips">
       {categoryL.map((category)=>(<div
        key={category._id}  
        className={`text-dark p-2 bd-highlight border-bottom font-weight-normal hover-overlay
              ${categoryId === category._id ? "text-selected" : ""}`}
              onClick={()=>handleOptions(category._id)} 
            
              >
      {category.title}
       </div>))}

        </div>
      </div>
      <div className="col-lg-10 ">
        <div className="container ">
          <div className="row mb-auto crd">
          <div className=" flex-grow-1 bd-highlight pb-3">
                <h4 style={{ marginTop: "10px" }}>Categories</h4>
              </div>
              <div className="row ">
              {errMsg && showErrorMsg(msg, errMsg)}
                {loading && showLoader()}
{successMsg&&showSuccessMsg(msg,successMsg)}
{popup&& cartResetPopup()}
        {categoryProduct.length>0?
          categoryProduct.map((product, index) => (
            <div className="product-container">
              <div
                className="card bg-secondary h-75 w-100 rounded-3 text-light  bs" style={{height:'340px'}}
                key={index}
              >
                <img
                  src={product.image[0]?product.image[0]:noimage}
                  className="card-img-top w-100 h-50 p-1"
                  alt={product.itemName}
                />

                <div className="card-body  p-3">
                  <h5 className="text-capitalize text-body">
                    {product.itemName}
                  </h5>
                  <h6>{product.netWeight ? product.netWeight : 0}</h6>
                  <div className="d-flex justified-content-sm-end text-dark">
                    <h6 className=" m-1 text-bold">
                      ₹{product.itemPrice}
                    </h6>
                    {cart.some((p) => p._id === product?._id && p.count>0)?
                    <div className="text-body1 fw-bolder text-center">
                    <AiOutlineMinus
                      className="  ms-2  bg-light rounded-circle "
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleQtyChange(e, product,'-')}
                    />
                  {cart.filter((p)=> p._id === product?._id && p.count>0)[0].count}
                    <AiOutlinePlus
                      className=" ms-2 rounded-circle"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleQtyChange(e, product,'+')}
                     
                    />
                      </div> :""}

                   {/* // {cart.some((p) => p._id === product?._id) ? ( */}
                   {cart.some((p) => p._id === product?._id && p.count>0) ? (
                    
                
                  <button
                  className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                  style={{
                    borderRadius: "5px",
                    color: "#003C7E",
                    border: "none",
                    background: "none",
                  }}
                    // onClick={() =>
                    //   dispatch({
                    //     type: "REMOVE_FROM_CART",
                    //     payload: product,
                    //   })
                    // }
                    onClick={() => { setSuccessMsg(true)
                      setMsg("Product Removed SuccessFully")
                        
                      timeout = displaySuccessMsg(setSuccessMsg, timeout);deleteFromCart(product);setCart(getCart())}}
                  >
                    Remove 
                  </button>
                 
                   ):(
                     
                      <button
                      className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                      style={{
                        borderRadius: "5px",
                        color: "#003C7E",
                        border: "none",
                        background: "none",
                      }}
                        // onClick={() =>
                        //   dispatch({
                        //     type: "ADD_TO_CART",
                        //     payload: product,
                        //   })
                        // }
                        // disabled={!prod.inStock}
                        onClick={() =>handleAddToCart(product)}
                      >
                        {/* {!prod.inStock ? "Out of Stock" : "Add to Cart"} */}
                        Add 
                      </button>
                   )}
                  </div>
                  <h6 className="text-muted m-2 text-decoration-line-through text-body1">
                    ₹ {product.itemPrice}
                  </h6>
                </div>
              </div>{" "}
            </div>
          )):<NoProduct/>}
        </div>
            {/* { categoryProduct.length>0?categoryProduct.map((product,index) => (
                <div className="product-container" key={index}>
                  <div
                    className="card bg-secondary h-75 w-100 rounded-3 text-light bs mb-5"
                    key={index}
                  >
                    <img
                      src={product.image[0]?product.image[0]:noimage}
                      className="card-img-top w-100 h-50"
                      alt={product.itemName}
                    />

                    <div className="card-body p-3">
                      <h5 className="text-capitalize text-body">
                        {product.itemName}
                      </h5>
                      <h6>{product.netWeight ? product.netWeight : 0}</h6>
                      <div className="d-flex ">
                        <h6 className=" m-2 text-b">
                         
                          ₹{product.customerPrice?product.customerPrice:0}
                        </h6>
                        {product.unitPerQuantity > 0 ? (
                          <div className=" text-title text-bold dright">
                            <AiOutlineMinus
                              className="id-icon text-dark ms-2 bg-light rounded-circle"
                              style={{ cursor: "pointer" }}
                              onClick={() => decrement(product._id)}
                            />
                            <span className="text-dark text-body1 ms-2 text-b">
                              {product.unitPerQuantity}
                            </span>
                            <AiOutlinePlus
                              className="id-icon text-dark ms-2 bg-light rounded-circle"
                              style={{ cursor: "pointer" }}
                              onClick={() => increment(product._id)}
                            />
                          </div>
                        ) : (
                          <button
                            // type="button"
                            className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                            style={{
                              borderRadius: "5px",
                              color: "#003C7E",
                              border: "none",
                              background: "none",
                            }}
                            onClick={() => {
                              // handleAddToCart(product.id);
                              increment(product.id);
                            }}
                          >
                           
                            ADD
                          </button>
                        )}
                      </div>
                      <h6 className="text-muted m-2 text-decoration-line-through text-body1">
                        ₹ {product.itemPrice}
                      </h6>
                    </div>
                  </div>{" "}
                </div>
              )):<NoProduct />} */}
          </div>
        </div>
      </div>
    </div>
 
  )
}

export default DisplayCategories