import React from "react";

const MerchantNotAvailable = () => {
  return (
    <div className="mx-auto ms-3 crd bg-white rounded ">
      <div className="px-5 pb-5 ">
        <div className=" flex-grow-1 bd-highlight  pb-3">
          <h4 style={{ marginTop: "10px" }}>We're sorry</h4>
        </div>

        <div className=" text-center ">
          <div className="row ">
<p>Orbit Mart does not provide services at your location</p>
<h6>Please try another location</h6>
          </div>
          <div className="row ">
            <img src="https://cdn.dribbble.com/users/692329/screenshots/4143602/location-services.png?compress=1&resize=400x300" alt="merchant not found" style={{height:"300px",width:"100vw"}} />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default MerchantNotAvailable;
