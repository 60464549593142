import React from 'react';
import { Link } from "react-router-dom";

import a2 from "./../../src/assets/images/a2.jpg";
import a3 from "./../../src/assets/images/a3.jpg";
import a4 from "./../../src/assets/images/a4.jpg";
import a1 from "./../../src/assets/images/a1.jpg";


function AgriMarchant() {
  return (
    <div>
        <div className="mx-5 crd">
          <h4 style={{ marginTop: "10px" }}>Agricultural Marchant's List</h4>
          {/* <p className="p1">Add Popular Categories to weekly line up</p> */}
          <div className="container catbx">
            <div className="row pt-5 pb-4 mx-6">
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={a4} />
                <h4>Sundar Agro</h4> <br/>
                {/* <p> upto 30% off</p> */}
                <Link to="/sundarproducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={a3} />
                <h4>Agro Special</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/sundarproducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={a2} />
                <h4>Fertilizer Zone</h4><br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/sundarproducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={a1} />
                <h4>Pesticide's Zone</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/sundarproducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default AgriMarchant