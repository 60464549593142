import React from 'react';
import { Link } from "react-router-dom";

import m1 from "./../../src/assets/images/m1.jpg";
import m2 from "./../../src/assets/images/m2.jpg";
import m3 from "./../../src/assets/images/m3.jpg";
import m4 from "./../../src/assets/images/m4.jpg";

function MediMarchant() {
  return (
    <div>
                <div className="mx-5 crd">
          <h4 style={{ marginTop: "10px" }}>Medicine Marchant's List</h4>
          {/* <p className="p1">Add Popular Categories to weekly line up</p> */}
          <div className="container catbx">
            <div className="row pt-5 pb-4 mx-6">
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={m1} />
                <h4>Govind Medical</h4> <br/>
                {/* <p> upto 30% off</p> */}
                <Link to="/GovindProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={m2} />
                <h4>Shyam Medical</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/GovindProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={m3} />
                <h4>Rama Medical</h4><br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/GovindProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={m4} />
                <h4>Manav Medical</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/GovindProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MediMarchant