import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";

const DrawerButton = ({ onClick }) => {
  return (
    <div className="hamburger_icon_container">
      <GiHamburgerMenu className="hamburger_icon" onClick={onClick} />
    </div>
  );
};

export default DrawerButton;
