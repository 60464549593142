import React from "react";
import { Link } from "react-router-dom";

import "../assets/themes/main2.css";
import "../assets/themes/orderdetails.css";
import "../assets/themes/profile.css";
import "../assets/themes/changepassword.css";
import "../assets/themes/trackorder.css";
import "../assets/themes/profilesetting.css";
import "../assets/themes/addressinfo.css";
import "../assets/themes/contact.css";
import "../assets/themes/myorder.css";
import "../assets/themes/about.css";
import "../assets/themes/personalinfo.css";

function Merchantprodetails() {
  return (
    <>
      <div className="card_container">
        <div className="prodetail_card">
          <div className="shoeBackground">
            <img src="img/logo.png" alt="" className="card_logo" />
            <a href="#" className="card_share">
              <i className="fas fa-share-alt" aria-hidden="true"></i>
            </a>
          </div>
          <div className="info">
            <div className="shoeName">
              <div>
                <h6>Milk 1 liter (pack)</h6>
                <p className="vegicon">
                  <i className="fa fa-circle" aria-hidden="true"></i>
                </p>
              </div>
              <h3 className="small">
                Amul<i className="fa fa-heart" aria-hidden="true"></i>
              </h3>
            </div>
            <div className="description">
              <h3 className="title">Product Info</h3>
              <p className="text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's.
              </p>
            </div>
            <div className="color-container">
              <h3 className="title">details</h3>
              <div className="desc_deatils">
                <div className="desc_deatils1">
                  <h6>Available</h6>
                  <span>In stock</span>
                </div>
                <div className="desc_deatils2">
                  <select className="custom-select">
                    <option selected="">Qty</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="desc_deatils3">
                  <h6 className="ml-3">unit price</h6>
                  <span>
                    <p className="ml-3">Rs.63</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="size_container">
              <h3 className="title">update</h3>
              <div className="sizes">
                <div className="chng_btn p-4">
                  <a
                    className="chnge_dele"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      margin: "2px",
                    }}
                  >
                    <i
                      className="fa fa-trash-o"
                      aria-hidden="true"
                      style={{ color: "inherit" }}
                    ></i>
                    delete
                  </a>
                  <a
                    className="chnge_continue"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      margin: "2px",
                    }}
                  >
                    <i
                      className="fa fa-arrow-right"
                      aria-hidden="true"
                      style={{ color: "inherit" }}
                    ></i>
                    coninue
                  </a>
                  <a
                    className="chnge_update"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      margin: "2px",
                    }}
                  >
                    <i
                      className="fa fa-pencil"
                      aria-hidden="true"
                      style={{ color: "inherit" }}
                    ></i>
                    update cart
                  </a>
                </div>
              </div>
            </div>
            <div className="buy-price">
              <a href="#" className="buy">
                <i className="fas fa-shopping-cart"></i>Procced
              </a>
              <div className="price">
                <h1>Rs.63</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Merchantprodetails;
