import React, { useContext, useState,useEffect } from "react";
//import Draggable from "react-draggable";
import { API } from "../../config/api";
import "./myProfile.css";
import { FaUser } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiBankFill } from "react-icons/ri";
import { IoSettingsSharp } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import leftImg from "../../assets/images/profileImg.png";
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import Input from "@mui/material/Input";
// import { FormGroup } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
import { StateContext } from "../../context/ContextProvider";
import AddressInformation from './sections/AddressInformation';  // Import subcomponents
import BankInformation from './sections/BankInformation';
import Settings from './sections/Settings';
import DeleteAccount from './sections/DeleteAccount';

import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { showLoader } from "../../compenent/helpers/loading";
import { showErrorMsg, showSuccessMsg } from "../../compenent/helpers/message";
import MyProfileimg from "../../assets/images/my profile.png";
//import { MdFormatLineSpacing } from "react-icons/md";
//import { useNavigate } from "react-router-dom";
const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;

function MyProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useContext(StateContext);
  const [profileMenus, setProfileMenus] = useState("Delete Account");
  // const [isProfileAccessed, setIsProfileAccessed] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Username:', username);
    console.log('Password:', password);
    setShowLoginForm(false); 
  };

  const handleDeleteClick = () => {
    setShowLoginForm(true); 
  };

  const handleMenuClick = (menu) => {
    setProfileMenus(menu);

    switch (menu) {
      case "Delete Account":
        navigate('/myprofile/delete-account');
        break;
      case "Personal Information":
        navigate('/myprofile');
        break;
      case "Address Information":
        navigate('/myprofile/address-information');
        break;
      case "Bank Information":
        navigate('/myprofile/bank-information');
        break;
      case "Settings":
        navigate('/myprofile/settings');
        break;
      default:
        break;
    }
  };

  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };

  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    }, 2000);
    return timeout;
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please enter First Name"),
    lastname: Yup.string().required("Please enter Last Name"),
    email: Yup.string()
      .required("Please enter Email")
      .email("Please enter valid Email ID"),
    gender: Yup.string()
      .required("Please select Gender")
      .test("test-gender", "Please select any one of gender", function (value) {
        if (value !== "MALE" && value != "FEMALE") {
          return false;
        }
        return true;
      }),
    mobile: Yup.string()
      .required("Please enter Mobile number")
      .matches(phoneRegex, "Please enter valid Mobile Number"),
  });
  const formik = useFormik({
    initialValues: {
      firstname: user.data.firstName,
      lastname: user.data.lastName,
      gender: user.data.gender,
      email: user.data.email,
      mobile: user.data.mobile,
    },
    validationSchema: validationSchema,
    onSubmit: async (userinfo) => {
      setLoading(true);
      const userdata = {
        firstName: userinfo.firstname,
        lastName: userinfo.lastname,
        email: userinfo.email,
        mobile: userinfo.mobile,
        gender: userinfo.gender,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-token": sessionStorage.getItem("token"),
        },
      };

      await axios
        .put(`${API}/auth/edit-profile`, userdata, config)
        .then(async (res) => {
          if (res.data.status == "SUCCESS") {
            await axios.get(`${API}/auth/get-profile`, config).then((res) => {
              setUser(res.data);
              setMsg("Profile updated succesfully");
              timeout = displaySuccessMsg(setSuccessMsg, timeout);
            });

            setLoading(false);
          }
        })
        .catch((err) => {
          // setMsg('Try again Later');
          // timeout = displayErrMsg(setErrMsg, timeout);
          // setLoading(false)
        });
    },
  });
  const handleGender = (e) => {
    formik.setFieldValue("gender", e.target.value, true);
  };
  return (
    <div className="main-profile-container">
      <div className="card-body inner-profile-container my-2">
        <div className="row profile-inner-contaienr">
          <div className="col-md-3 left-img-section">
            {/* <img src={leftImg} /> */}
            <div className="top-left-bar-profile">
              <img src={MyProfileimg} />
              <div>
                <p>Welcome</p>
                <h1>UserName</h1>
              </div>
            </div>
            <div className="next-container-profile">
              <div
                className={`menuOptions ${
                  profileMenus === "Personal Information"
                    ? "profilemenusActive"
                    : ""
                }`}
                onClick={() => handleMenuClick("Personal Information")}
              >
                <h2>
                  <FaUser />
                </h2>{" "}
                <h1>
                  <FaUser /> &nbsp;&nbsp; Personal Information{" "}
                  <IoIosArrowForward />
                </h1>
              </div>
              <div
                className={`menuOptions ${
                  profileMenus === "Address Information"
                    ? "profilemenusActive"
                    : ""
                }`}
                onClick={() => handleMenuClick("Address Information")}
              >
                <h2>
                  <IoLocationSharp />
                </h2>{" "}
                <h1>
                  <IoLocationSharp /> &nbsp;&nbsp; Address Information{" "}
                  <IoIosArrowForward />
                </h1>
              </div>

              <div
                className={`menuOptions ${
                  profileMenus === "Bank Information"
                    ? "profilemenusActive"
                    : ""
                }`}
                onClick={() => handleMenuClick("Bank Information")}
              >
                <h2>
                  <RiBankFill />
                </h2>{" "}
                <h1>
                  <RiBankFill /> &nbsp;&nbsp; Bank Information{" "}
                  <IoIosArrowForward />
                </h1>
              </div>
              <div
                className={`menuOptions ${
                  profileMenus === "Settings" ? "profilemenusActive" : ""
                }`}
                onClick={() => handleMenuClick("Settings")}
              >
                <h2>
                  <IoSettingsSharp />
                </h2>{" "}
                <h1>
                  <IoSettingsSharp /> &nbsp;&nbsp; Settings{" "}
                  <IoIosArrowForward />
                </h1>
              </div>
              <div
                className={`menuOptions ${
                  profileMenus === "Delete Account" ? "profilemenusActive" : ""
                }`}
                onClick={() => handleMenuClick("Delete Account")}
              >
                <h2>
                  <IoSettingsSharp />
                </h2>{" "}
                <h1>
                  <IoSettingsSharp /> &nbsp;&nbsp; Delete Account{" "}
                  <IoIosArrowForward />
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-6 right-side-secton">
            {profileMenus === "Personal Information" ? (
              <div className="mb-4 bs">
                {" "}
                {/* <div className="card mb-4 bs"> */}
                <div
                // className="card-header py-3 "
                // style={{ backgroundColor: "#003C7E" }}
                >
                  <h5 className="mb-auto headerProfile">My Profile</h5>
                </div>
                <div className="  px-2 pt-4">
                  <form
                    style={{
                      width: "100%",
                    }}
                    onSubmit={formik.handleSubmit}
                  >
                    {successMsg && showSuccessMsg(msg, successMsg)}
                    {loading && showLoader()}
                    {errMsg && showErrorMsg(msg, errMsg)}
                    <div className="row borderremove">
                      <div className="row borderremove">
                        <div className="form-outline mb-1" id="AddressElemet-1">
                          <label className="form-label">
                            Personal Information*
                          </label>
                          <br />
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="First Name"
                              id="firstname"
                              name="firstname"
                              value={formik.values.firstname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.errors.firstname &&
                                formik.touched.firstname
                              }
                              helperText={
                                formik.errors.firstname &&
                                formik.touched.firstname
                                  ? formik.errors.firstname
                                  : ""
                              }
                              className="form-control "
                              style={{ height: "2rem", width: "40%" }}
                            ></input>

                            <input
                              type="text"
                              placeholder="Last Name"
                              id="lastname"
                              name="lastname"
                              value={formik.values.lastname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.errors.lastname &&
                                formik.touched.lastname
                              }
                              helperText={
                                formik.errors.lastname &&
                                formik.touched.lastname
                                  ? formik.errors.lastname
                                  : ""
                              }
                              className="form-control  ms-2"
                              style={{ height: "2rem", width: "40%" }}
                            ></input>
                          </div>

                          <br />
                        </div>
                      </div>
                      <div
                        className="genderbox pb-1 ms-2"
                        id="gender"
                        name="gender"
                        error={formik.errors.gender && formik.touched.gender}
                        helperText={
                          formik.errors.gender && formik.touched.gender
                            ? formik.errors.gender
                            : ""
                        }
                      >
                        <label className="form-label">Gender</label>
                        <br />
                        <div className="d-flex ">
                          <input
                            type="radio"
                            id="male"
                            name="male"
                            value="MALE"
                            checked={
                              formik.values.gender === "MALE" ? true : false
                            }
                            onChange={(e) => handleGender(e)}
                          />
                          <label className="p-2">Male</label>
                          <input
                            type="radio"
                            id="female"
                            name="female"
                            value="FEMALE"
                            checked={
                              formik.values.gender === "FEMALE" ? true : false
                            }
                            onChange={(e) => handleGender(e)}
                          />
                          <label className="p-2">Female</label>
                        </div>
                        <br />
                      </div>
                      <div className="row borderremove">
                        <div className="form-outline mb-4">
                          <label className="form-label">Email Address*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Email Address"
                            id="email"
                            className="form-control  "
                            style={{
                              height: "2rem",
                              width: "60%",
                              textTransform: "lowercase",
                            }}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email && formik.touched.email}
                            helperText={
                              formik.errors.email && formik.touched.email
                                ? formik.errors.email
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        <div className="form-otline mb-4">
                          <label className="form-label">Mobile No*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Mobile No"
                            id="mobile"
                            className="form-control "
                            style={{ height: "2rem", width: "60%" }}
                            name="mobile"
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.mobile && formik.touched.mobile
                            }
                            helperText={
                              formik.errors.mobile && formik.touched.mobile
                                ? formik.errors.mobile
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        {/* <Link to="/PaymentMethod"> */}

                        <button
                          type="submit"
                          className="btn updatebtn col-sm-6"
                        >
                          UPDATE
                        </button>
                        <div className="col-sm-6"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            {/* address */}
            {profileMenus === "Address Information" ? (
              <div className="mb-4 bs">
                {" "}
                {/* <div className="card mb-4 bs"> */}
                <div
                // className="card-header py-3 "
                // style={{ backgroundColor: "#003C7E" }}
                >
                  <h5 className="mb-auto headerProfile">Address Information</h5>
                </div>
                <div className="  px-2 pt-4">
                  <form
                    style={{
                      width: "100%",
                    }}
                    onSubmit={formik.handleSubmit}
                  >
                    {successMsg && showSuccessMsg(msg, successMsg)}
                    {loading && showLoader()}
                    {errMsg && showErrorMsg(msg, errMsg)}
                    <div className="row borderremove">
                      <div className="row borderremove">
                        <div className="form-outline mb-1" id="AddressElemet-1">
                          <label className="form-label">Address*</label>
                          <br />
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="Address"
                              id="address"
                              name="address"
                              // value={formik.values.firstname}
                              // onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.errors.firstname &&
                                formik.touched.firstname
                              }
                              helperText={
                                formik.errors.firstname &&
                                formik.touched.firstname
                                  ? formik.errors.firstname
                                  : ""
                              }
                              className="form-control "
                              style={{ height: "2rem", width: "40%" }}
                            ></input>
                          </div>

                          <br />
                        </div>
                      </div>
                      <div
                        className="genderbox pb-1 ms-2"
                        // id="gender"
                        // name="gender"
                        // error={formik.errors.gender && formik.touched.gender}
                        // helperText={
                        //   formik.errors.gender && formik.touched.gender
                        //     ? formik.errors.gender
                        //     : ""
                        // }
                      >
                        <label className="form-label">Zipcode</label>
                        <br />
                        <div className="d-flex ">
                          <input
                            type="text"
                            placeholder="Zip"
                            id="Zipcode"
                            name="Zipcode"
                            // value={formik.values.firstname}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // error={
                            //   formik.errors.firstname &&
                            //   formik.touched.firstname
                            // }
                            // helperText={
                            //   formik.errors.firstname &&
                            //   formik.touched.firstname
                            //     ? formik.errors.firstname
                            //     : ""
                            // }
                            className="form-control "
                            style={{ height: "2rem", width: "40%" }}
                          ></input>
                        </div>
                        <br />
                      </div>
                      <div className="row borderremove">
                        <div className="form-outline mb-4">
                          <label className="form-label">Country*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Country"
                            id="Country"
                            className="form-control  "
                            style={{
                              height: "2rem",
                              width: "60%",
                              textTransform: "lowercase",
                            }}
                            name="Country"
                            // value={formik.values.email}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // error={formik.errors.email && formik.touched.email}
                            // helperText={
                            //   formik.errors.email && formik.touched.email
                            //     ? formik.errors.email
                            //     : ""
                            // }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        <div className="form-otline mb-4">
                          <label className="form-label">State*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="State"
                            id="State"
                            className="form-control "
                            style={{ height: "2rem", width: "60%" }}
                            name="State"
                            // value={formik.values.mobile}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.errors.mobile && formik.touched.mobile
                            }
                            helperText={
                              formik.errors.mobile && formik.touched.mobile
                                ? formik.errors.mobile
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        <div className="form-otline mb-4">
                          <label className="form-label">City*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="City"
                            id="City"
                            className="form-control "
                            style={{ height: "2rem", width: "60%" }}
                            name="City"
                            // value={formik.values.mobile}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.errors.mobile && formik.touched.mobile
                            }
                            helperText={
                              formik.errors.mobile && formik.touched.mobile
                                ? formik.errors.mobile
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        {/* <Link to="/PaymentMethod"> */}

                        <button
                          type="submit"
                          className="btn updatebtn col-sm-6"
                        >
                          UPDATE
                        </button>
                        <div className="col-sm-6"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            {/* bank */}
            {profileMenus === "Bank Information" ? (
              <div className="mb-4 bs">
                {" "}
                {/* <div className="card mb-4 bs"> */}
                <div
                // className="card-header py-3 "
                // style={{ backgroundColor: "#003C7E" }}
                >
                  <h5 className="mb-auto headerProfile">Bank Information</h5>
                </div>
                <div className="  px-2 pt-4">
                  <form
                    style={{
                      width: "100%",
                    }}
                    onSubmit={formik.handleSubmit}
                  >
                    {successMsg && showSuccessMsg(msg, successMsg)}
                    {loading && showLoader()}
                    {errMsg && showErrorMsg(msg, errMsg)}
                    <div className="row borderremove">
                      <div className="row borderremove">
                        <div className="form-outline mb-1" id="AddressElemet-1">
                          <label className="form-label">Bank Name*</label>
                          <br />
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="Bank Name"
                              // id="firstname"
                              // name="firstname"
                              // value={formik.values.firstname}
                              // onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.errors.firstname &&
                                formik.touched.firstname
                              }
                              helperText={
                                formik.errors.firstname &&
                                formik.touched.firstname
                                  ? formik.errors.firstname
                                  : ""
                              }
                              className="form-control "
                              style={{ height: "2rem", width: "40%" }}
                            ></input>
                          </div>

                          <br />
                        </div>
                      </div>
                      <div
                        className="genderbox pb-1 ms-2"
                        id="gender"
                        name="gender"
                        error={formik.errors.gender && formik.touched.gender}
                        helperText={
                          formik.errors.gender && formik.touched.gender
                            ? formik.errors.gender
                            : ""
                        }
                      >
                        <label className="form-label">Bank Account Type*</label>
                        <br />
                        <div className="d-flex ">
                          <input
                            type="radio"
                            id="male"
                            name="male"
                            value="MALE"
                            checked={
                              formik.values.gender === "MALE" ? true : false
                            }
                            onChange={(e) => handleGender(e)}
                          />
                          <label className="p-2">Saving</label>
                          <input
                            type="radio"
                            id="female"
                            name="female"
                            value="FEMALE"
                            checked={
                              formik.values.gender === "FEMALE" ? true : false
                            }
                            onChange={(e) => handleGender(e)}
                          />
                          <label className="p-2">current</label>
                        </div>
                        <br />
                      </div>
                      <div className="row borderremove">
                        <div className="form-outline mb-4">
                          <label className="form-label">Branch Name*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="Branch Name"
                            id="Branch Name"
                            className="form-control  "
                            style={{
                              height: "2rem",
                              width: "60%",
                              textTransform: "lowercase",
                            }}
                            // name="email"
                            // value={formik.values.email}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={formik.errors.email && formik.touched.email}
                            helperText={
                              formik.errors.email && formik.touched.email
                                ? formik.errors.email
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        <div className="form-otline mb-4">
                          <label className="form-label">IFSC Code*</label>
                          <br />
                          <input
                            type="text"
                            placeholder="IFSC Code"
                            id="IFSC Code"
                            className="form-control "
                            style={{ height: "2rem", width: "60%" }}
                            // name="mobile"
                            // value={formik.values.mobile}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.errors.mobile && formik.touched.mobile
                            }
                            helperText={
                              formik.errors.mobile && formik.touched.mobile
                                ? formik.errors.mobile
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row borderremove">
                        {/* <Link to="/PaymentMethod"> */}

                        <button
                          type="submit"
                          className="btn updatebtn col-sm-6"
                        >
                          UPDATE
                        </button>
                        <div className="col-sm-6"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            {/* setting */}
            {profileMenus === "Settings" ? (
              <Settings />
            ) : null}

            {/* Delete Account */}

            {profileMenus === "Delete Account" ? (
              <div className="mb-4 bs">
                {" "}
                {/* <div className="card mb-4 bs"> */}
                <div
                // className="card-header py-3 "
                // style={{ backgroundColor: "#003C7E" }}
                >
                  <h5 className="mb-auto headerProfile">Delete Account</h5>
                </div>
                <div className="  px-2 pt-4">
                  {!showLoginForm && (
                  <div className="accountDelete-bar">
                    <div className="inner-accountDelete-bar">
                      <h2>Deleting account is a permanent action</h2>
                      <br />
                      <p>
                        Please be advised that the deletion of your account is a
                        permanent action. Once your account is deleted, you will
                        lose orbitmart data including order history & it will no
                        longer be accessible and cannot be restored under any
                        circumstances.
                      </p>
                    </div>
                  
                    <div className="leaving-us">
                       <p>Please tell us why you’re leaving us</p>
                       <textarea className="textAra" rows="5" cols="50" placeholder="Your feedback will help us improve orbitmart."></textarea>
                    </div>
                    <button onClick={handleDeleteClick} className="delete-btn">Delete Account</button>
                  </div> )}
                  {/* login page */}
                  {showLoginForm && (
                  <div className="login-bar-delete-account">
                    <div className="inner-login-bar-delete-account">
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <label htmlFor="username" style={{margin: '5px 0'}}>Username</label>
                            <input
                              type="text"
                              id="username"
                              value={username}
                              placeholder="Enter Username"
                              onChange={(e) => setUsername(e.target.value)}
                              required
                              className="inpus"
                            />
                          </div>
                          <div style={{margin: '20px 0'}}>
                            <label htmlFor="password" style={{margin: '5px 0'}}>Password</label>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              placeholder="Enter Password"
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              className="inpus"
                            />
                          </div>
                          <button type="submit" className="delete-btn">
                            Delete Account
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
            ) : null}
            {/* end */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
