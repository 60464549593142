import React from "react";
function Marchant() {

  return (
    <>

      <section>
        <div>
          <img
            src="/assets/images/Banners/banner1.jpg"
            width="100%"
            style="height: 390px"
          />
        </div>
      </section>
      <section className="procrdpg">
        <div className="row">
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img
                src="/assets/images/dairycrd.jpg"
                alt="Strawberry"
                className="img-fruit"
              />
              <h3>Strawberries</h3>
              <div className="control-btn leftrightbtn">
                <span className="prev leftarrow">
                  <i className="fa fa-long-arrow-left"></i>
                </span>
                <span className="next rightarrow">
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </div>
              <p>hug a vegetarian, save a cow</p>
              <div className="properties_qty">
                <div className="propertiesqty">
                  <h4>Quantity</h4>
                  <i className="fa fa-minus"></i>
                  <span className="number">1</span>
                  <i className="fa fa-plus"></i>
                </div>
                <div className="propertiesprices">
                  <h4>Total Price</h4>
                  <span className="price-inr">
                    <i className="fa fa-inr"></i>
                    <span className="amount">7</span>
                  </span>
                </div>
                <div className="propertiesdelivery">
                  <h4>Delivery</h4>
                  <i className="fa fa-fighter-jet"></i>
                  <span className="time">In 60 mins</span>
                </div>
                <input className="ip-add-cart" type="button" value="Add to cart" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Marchant;
