import React, { useContext, useState } from "react";

import oil from "../assets/img/oil.jpg";
import { StateContext } from "../context/ContextProvider";

const SeeallRecommended = () => {
  const { recommended } = useContext(StateContext);

  return (
    <div className="container ">
      <div className="row mb-2">
        {recommended.map((product) => (
          <div className="product-container" key={product}>
            <div className="item img-thumbnail text-center pb-1 mx-auto  mb-3 tody">
              <img src={product.avatar} className="card-img-top w-100 h-150" />

              <div className="card-body p-3">
                <h5 className="item  text-center pb-2 mx-auto  tody">
                  {product.firmName}
                </h5>
                {/* <h6>{product.netWeight ? product.netWeight : 0}</h6>
                      <div className="d-flex "> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeeallRecommended;