export const getItemsFromLocalStorage = (key) => {
  if (typeof key !== "string") return new Error("Please pass a string");
  const item = window.localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return undefined;
};
export const removeItemFromLocalStorage = (key) => {
  if (typeof key !== "string") return new Error("Please pass a string");
  window.localStorage.removeItem(key);
};
export const setItemToLocalStorage = (key, value) => {
  if (typeof key !== "string") return new Error("Please pass a string");
  window.localStorage.setItem(key, JSON.stringify(value));
};
