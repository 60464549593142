import React, { useState,createContext,useEffect} from 'react'

import axios from 'axios'

export const dataContext = createContext();
//Fetch MerchantType data from API
export const API1="https://api.orbitmart.co.in/api/v1/merchant/Type"
export const API2='https://api.orbitmart.co.in/api/v1/merchant/Type/bymerchantTypeId'
// export const API3='143.244.132.221:4002/api/v1/product/byMerchant/:id?page=2'

export function MerchantProvider(props) {
    const [domainType, setDomainType] = useState([])
    const [merchantL, setMerchantL] = useState([])
    const [domain,setDomain]=useState('')
    const [productL, setProductL]=useState([])
    const getDomainType=async()=>{
        await axios.get(API1).then(response => {setDomainType(response.data);});
      }
     

useEffect(()=>{getDomainType()},[])

  return (
<dataContext.Provider value={{ dt: [domainType, setDomainType], ml: [merchantL, setMerchantL],dm:[domain,setDomain],pl:[productL,setProductL]}} >
    {props.children}
</dataContext.Provider>
  )
}

