import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import dairy from "../assets/images/dairy.jpg";
import {
  AiOutlineShoppingCart,
  AiOutlineMinusSquare,
  AiOutlinePlusSquare,
} from "react-icons/ai";
const sublinks = [
  { id: 1, name: "category1" },
  { id: 2, name: "category2" },
  { id: 3, name: "category3" },
  { id: 4, name: "category4" },
  { id: 5, name: "category5" },
  { id: 6, name: "category6" },
  { id: 7, name: "category7" },
  { id: 8, name: "category8" },
  { id: 9, name: "category9" },
];
const productList1 = [
  {
    id: 1,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 2,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 3,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 4,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 5,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 6,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 7,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 8,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 9,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 10,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 11,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
];
const productList2 = [
  {
    id: 1,
    image: dairy,
    itemName: "veg",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 2,
    image: dairy,
    itemName: "veg",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 3,
    image: dairy,
    itemName: "veg",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 4,
    image: dairy,
    itemName: "veg",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 5,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 6,
    image: dairy,
    itemName: "food",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
];
const productList3 = [
  {
    id: 1,
    image: dairy,
    itemName: "fruits",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 2,
    image: dairy,
    itemName: "fruits",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 3,
    image: dairy,
    itemName: "fruits",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 4,
    image: dairy,
    itemName: "fruits",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
  {
    id: 5,
    image: dairy,
    itemName: "fruits",
    actualSellingPrice: 45,
    itemPrice: 55,
    netWeight: "100gm",
    qty: null,
  },
  {
    id: 6,
    image: dairy,
    itemName: "fruits",
    actualSellingPrice: 45,
    itemPrice: 55,
    qty: null,
  },
];

const SeeAll = () => {
  // const [quant, setQuant] = useState();
  const [mainValue, setMainValue] = useState(1);
  const [productList, setProductList] = useState(productList1);
  // const handleAddToCart = async (index) => {
    // const config={headers:{"Content-Type":"application/json"}}
    // const API="https://api.orbitmart.co.in/api/v1/cart/add"
    // const data={productId:product._id,userId:"62d15f53481f5b19f9976386"}
    // await axios.post(API,data,config)
    // setQuant(quant + 1);
  // };
  const increment = (product_id) => {
    setProductList((productList) =>
      productList.map((item) =>
        product_id === item.id ? { ...item, qty: item.qty + 1 } : item
      )
    );
  };
  const decrement = (product_id) => {
    setProductList((productList) =>
      productList.map((item) =>
        product_id === item.id ? { ...item, qty: item.qty - (item.qty > 0 ? 1:0) } : item
      )
    );
  };
  const handleOptions = (id) => {
    setMainValue(id);
    switch (id) {
      case 1: {
        setProductList(productList1);
        break;
      }
      case 2: {
        setProductList(productList2);
        break;
      }
      case 3: {
        setProductList(productList3);
        break;
      }
      default: {
        setProductList(productList1);
      }
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-2">
          <div className="scrolleffect d-flex flex-column bd-highlight mb-3 overflow-auto h-50 d-inline-block scrollbar scrollbar-lady-lips">
            {sublinks.map((link, id) => (
              //   <NavLink
              // to={`/${link.name}`}
              // key={link.name}
              //   onClick={handleCloseSideBar}
              //   style={({ isActive }) => ({
              //     backgroundColor: isActive ? superWhite : superBlue,
              //     color: isActive ? superBlue : superWhite,
              //   })}
              //   className={({ isActive }) => (isActive ? activeLink : normalLink)}
              //   >
              <div
                // className="text-dark p-2 bd-highlight border-bottom font-weight-normal hover-overlay"
                className={`text-dark p-2 bd-highlight border-bottom font-weight-normal hover-overlay
                ${mainValue === link.id ? "text-selected" : ""}
                `}
                style={{ cursor: "pointer" }}
                onClick={() => handleOptions(link.id)}
                key={id}
              >
                {link.name}
              </div>
              //    </NavLink>
            ))}
          </div>
        </div>
        <div className="col-lg-10">
          <div className="container ">
            <div className="row mb-2">
              {productList.map((product, index) => (
                <div className="product-container" key={index}>
                  <div
                    className="card bg-secondary h-75 w-100 rounded-3 text-light bs mb-5"
                    key={index}
                  >
                    <img
                      src={product.image}
                      className="card-img-top w-100 h-50"
                      alt={product.itemName}
                    />

                    <div className="card-body p-3">
                      <h5 className="text-capitalize text-body">
                        {product.itemName}
                      </h5>
                      <h6>{product.netWeight ? product.netWeight : 0}</h6>
                      <div className="d-flex ">
                        <h6 className=" m-2 text-b">
                          ₹{product.actualSellingPrice}
                        </h6>
                        {product.qty > 0 ? (
                          <div className=" text-title text-bold dright">
                            <AiOutlineMinus
                              className="id-icon text-dark ms-2 bg-light rounded-circle"
                              style={{ cursor: "pointer" }}
                              onClick={() => decrement(product.id)}
                            />
                            <span className="text-dark text-body1 ms-2 text-b">
                              {product.qty}
                            </span>
                            <AiOutlinePlus
                              className="id-icon text-dark ms-2 bg-light rounded-circle"
                              style={{ cursor: "pointer" }}
                              onClick={() => increment(product.id)}
                            />
                          </div>
                        ) : (
                          <button
                            // type="button"
                            className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                            style={{
                              borderRadius: "5px",
                              color: "#003C7E",
                              border: "none",
                              background: "none",
                            }}
                            onClick={() => {
                              // handleAddToCart(product.id);
                              increment(product.id);
                            }}
                          >
                            {/* <AiOutlineShoppingCart /> */}
                            ADD
                          </button>
                        )}
                      </div>
                      <h6 className="text-muted m-2 text-decoration-line-through text-body1">
                        ₹ {product.itemPrice}
                      </h6>
                    </div>
                  </div>{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeAll;
