import React from 'react'

const MerchantNotFound = () => {
  return (
    <div className="mx-auto ms-3 crd bg-white rounded ">
    <div className="px-5 pb-5 ">
      <div className=" mx-auto pb-3 img-thumbnail w-25 h-25">
        <h5 style={{ marginTop: "10px" }}>We're sorry</h5>
   

      <div className="text-center  ">

        <div className="row ">
        <h6>Merchant Not Found</h6>
          <img src="https://cdn0.iconfinder.com/data/icons/scenicons-e-commerce-flat-icons/512/Ecommerce_Set-50-512.png" alt="merchant not found"  style={{margin:"auto"}} />
        </div>
      </div>
      </div>
    </div>
    <div></div>
  </div>

  )
}

export default MerchantNotFound