import React from 'react'

const MyCategory = () => {
    return (
        <div className="mx-auto ms-3 crd bg-white rounded">
            <div className="px-5 pb-5 container d-block justify-content-center ">
                <h4>Categories</h4>
                <div style={{display: 'grid', placeItems: 'center' ,height: '60vh'}}>
                    <div>
                        <h6 style={{ textAlign: 'center' }}>Category Coming Soon...</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCategory