import React from 'react';
import { Link } from "react-router-dom";

import govind from "./../../src/assets/images/govind.jpg";
import motherdairy from "./../../src/assets/images/motherdairy.jpg";
import amul from "./../../src/assets/images/amul.jpg";
import organic from "./../../src/assets/images/organic.jpg";


function DairyMarchant() {
  return (
    <div>
                <div className="mx-5 crd">
          <h4 style={{ marginTop: "10px" }}>Dairy Marchant's List</h4>
          {/* <p className="p1">Add Popular Categories to weekly line up</p> */}
          <div className="container catbx">
            <div className="row pt-5 pb-4 mx-6">
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={govind} />
                <h4>Govind Milk Products</h4> <br/>
                {/* <p> upto 30% off</p> */}
                <Link to="/AmulProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={motherdairy} />
                <h4>Mother dairy</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/AmulProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 procrd">
                <img src={amul} />
                <h4>Amul</h4><br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/AmulProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 fruimg procrd">
                <img src={organic} />
                <h4>Organic Farm</h4> <br/>
                {/* <p>upto 30% off</p> */}
                <Link to="/AmulProducts">
                  <button type="button" className="btn btn-success">
                    View
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default DairyMarchant