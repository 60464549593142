import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./NewCard.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import noimage from "../assets/images/noimage.jpg";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import "./OrderDetails.css";
import { StateContext } from "../context/ContextProvider";
import { Box } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import { API } from "../config/api";

const OrderDetails = () => {
  const { orderInfo, setMyOrder } = useContext(StateContext);
  const [activestep, setActiveStep] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totItem, setTotItem] = useState(0);
  const [gst, setGst] = useState(0);
  const userId = window.localStorage.getItem("userId");
  const [showStep, setShowStep] = useState(false);
  let navigate = useNavigate();

  function handleClick() {
    navigate("/paymentmethod");
  }
  const steps = ["PENDING", "PROCESSING", "PACKAGING", "SHIPPING", "DELIVERED"];

  useEffect(() => {
    let st = parseInt(
      steps.findIndex((step) => step === orderInfo.orderStatus)
    );
    console.log("step", st);
    if ((st) => 0) {
      setActiveStep(st);
      setShowStep(true);

      setDiscount(
        orderInfo.productDetails
          .map((pr) => {
            if (pr.productId?.discountType === "FIXED") {
              return parseInt(pr.productId?.customerDiscount);
            } else {
              return (
                (parseInt(pr.productId?.itemPrice) *
                  parseInt(pr.productId?.customerDiscount)) /
                100
              );
            }
          })
          .reduce((a, b) => a + b)
      );

      setTotItem(
        orderInfo?.productDetails.map((q) => q.quantity).reduce((a, b) => a + b)
      );
      setGst(
        (orderInfo?.totalPrice *
          parseInt(orderInfo.productDetails[0].productId?.gst)) /
          100
      );
    } else {
      setShowStep(false);
    }

    //eslint-disable-next-line
  }, []);

  const cancelOrder = (orderid) => {
    deleteOrder(orderid);
    navigate("/myorder");
  };

  const deleteOrder = async (id) => {
    await axios
      .delete(`${API}/order/${id}`)
      .then((res) => console.log(res.data));
    await axios
      .get(`${API}/order/user/${userId}`)
      .then((res) => setMyOrder(res.data.data.reverse()));
  };
  const showPayment = () => {
    if (
      orderInfo.orderStatus === "PENDING" ||
      orderInfo.orderStatus === "REJECTED" ||
      orderInfo.orderStatus === "PROCESSING"
    ) {
    } else if (
      orderInfo.paymentMode === "NOT SELECTED" &&
      showStep === true &&
      orderInfo.orderStatus !== "DELIVERED"
    ) {
      return (
        <Grid
          container
          component="main"
          className="justify-content-center mt-3 p-1   "
        >
          {/* selct payment div */}
          {/* <Box sx={{ mt: 2, textTransform: "lowercase", width: 900 }}>
            <table width="100%">
              <tr>
                <td>
                  <p>
                    {" "}
                    Your order has been confirmed.
                    <br />
                    Please select Payment method
                  </p>
                </td>
                <td>
                  <div
                    className="btn btn-succcess px-2 py-2 bg-success text-white  mx-auto"
                    onClick={handleClick}
                  >
                    Select payment
                  </div>
                </td>
              </tr>
            </table>{" "}
          </Box> */}
        </Grid>
      );
    }
    // }
  };

  console.log("order info", orderInfo);

  const showAction = () => {
    if (orderInfo.orderStatus === "PENDING") {
      return (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            variant="contained"
            onClick={(e) => cancelOrder(orderInfo._id)}
          >
            Cancel Order
          </Button>
        </Box>
      );
    }
  };

  const showDeliveryTime = () => {
    if (
      orderInfo.orderStatus === "PENDING" ||
      orderInfo.orderStatus === "PROCESSING"
    ) {
      //
    } else if (orderInfo.orderStatus === "REJECTED") {
      return (
        <div className="order-details">
          <div
            style={{
              padding: "10px 12px",
              textAlign: "center",
              borderRadius: "5px",
              backgroundColor: "#003B95",
              color: "white",
              fontSize: "20px",
            }}
            variant="raised"
            size="small"
          >
            Order Rejected
          </div>
        </div>
      );
    } else if (showStep === true && orderInfo.orderStatus !== "DELIVERED") {
      const months = {
        1: "January",
        2: "Feb",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      };

      let dateFormatset = orderInfo.deliveryDate;
      console.log(dateFormatset);
      const splittedDate = dateFormatset.split("/");
      console.log(splittedDate);
      const dateset = splittedDate[0];
      console.log(dateset);
      const monthset = months[splittedDate[1]];
      console.log(monthset);
      const yearset = splittedDate[2];
      console.log(yearset);

      const finaldate = dateset + " " + monthset + " " + yearset;
      console.log(finaldate);
      const d = new Date("13 feb 2023");
      console.log(d);

      var timew = orderInfo.deliveryTime;
      const splittedTime = timew.split(":");
      const amOrpm = splittedTime[0] >= 12 ? "PM" : "AM";
      const hourss = splittedTime[0] % 12 || 12;
      let hoursset = "";
      if (hourss < 10) {
        hoursset = "0";
      }

      let minuteSet = "";
      if (splittedTime[1] === 0) {
        minuteSet = "0";
      }
      let finalTime =
        hoursset + hourss + ":" + minuteSet + splittedTime[1] + " " + amOrpm;

      if (orderInfo.deliveryDate === "") {
        finalTime = "";
      }
      console.log(finalTime);

      return (
        <div className="order-details">
          <Card sx={{ width: 900, margin: "2px" }}>
            <CardActionArea>
              <div>
                <CardContent>
                  <Typography variant="p" color="text.secondary">
                    Delivery Time
                  </Typography>
                  <div>{`${finaldate} : ${finalTime}`}</div>
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </div>
      );
    }
  };

  return (
    <>
      <Grid
        container
        component="main"
        className="justify-content-center mt-3 mb-3 stepper-main-sec"
      >
        <Box sx={{ width: 900, mb: 2 }}>
          {showStep ? (
            <Stepper activeStep={activestep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Box sx={{ mt: 2, mb: 2 }}>
              <div className="alert alert-warning">
                ORDER {orderInfo?.orderStatus}
              </div>
            </Box>
          )}
        </Box>
      </Grid>

      {showPayment()}
      {showDeliveryTime()}

      <div className="order-details">
        <Card sx={{ width: 900 }}>
          <CardActionArea>
            <div>
              <CardContent>
                <Typography variant="p" color="text.secondary">
                  Delivering to
                </Typography>

                <div>{`${orderInfo.address},${orderInfo.city},${orderInfo.pincode}`}</div>
              </CardContent>
            </div>
          </CardActionArea>
        </Card>
      </div>

      <br />

      <div className="order-details">
        <Card sx={{ width: 900 }}>
          <CardActionArea>
            <div>
              <CardContent>
                <Typography
                  variant="p"
                  sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                  color="primary"
                >
                  {orderInfo.orderNo}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: 40,
                    justifyContent: "stretch",
                  }}
                >
                  <div style={{ width: "50px", paddingTop: "10px" }}>
                    <CardMedia
                      component="img"
                      image={
                        orderInfo?.merchantId?.avatar[0]
                          ? orderInfo?.merchantId?.avatar[0]
                          : noimage
                      }
                      alt="product"
                    />
                  </div>

                  <small>
                    {" "}
                    {orderInfo.merchantId?.firmName}
                    <div>
                      {orderInfo.merchantId?.address},
                      {orderInfo.merchantId?.district}-
                      {orderInfo.merchantId?.pincode}(
                      {orderInfo.merchantId?.country})<br />
                      {orderInfo.merchantId?.mobile}
                    </div>
                  </small>
                </div>
              </CardContent>
            </div>
          </CardActionArea>
        </Card>
      </div>

      <Grid container component="main">
        <Grid
          item
          sm={10}
          md={8}
          lg={7}
          sx={{ pt: 3, mx: "auto", mb: 3, }}
          //  component={Paper}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              columnGap: "3rem",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", paddingTop: "10px" }}
              // color="#000a12"
              color="#003C7E"
              fontSize={20}
            >
              Item List
            </Typography>
            <div
              style={{
                padding: "10px 10px 7px  10px",
                borderRadius: "1px",
                backgroundColor: "#CBE0FF",
                color: "#003B95",
                // fontSize:"20"
              }}
              variant="raised"
              size="small"
            >
              Total Item : {totItem}
            </div>
          </Box>
          <Box 
          className="order-info-details"
          sx={{
            padding: '20px',
           }}>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", paddingTop: "10px" }}
              color="#003C7E"
              fontSize={18}
            >
              Order Summary
            </Typography>
            <table style={{borderTop: '1px solid rgba(99, 99, 99, 0.2)', marginTop: '20px'}}>
              {orderInfo.productDetails.map((pro) => (
                <tr className="order-summary-cart order-summary-cartt" style={{borderTop: '1px solid rgba(99, 99, 99, 0.2)'}}>
                  <td>
                    <img
                      className="h-25 order-summary-cart-img"
                      src={pro.productId?.image[0]}
                      alt={pro.productId?.itemName}
                    />
                  </td>
                  <td>
                    <span className="text-cl-bl fs-6 fw-bold">
                      {pro.productId?.itemName}
                    </span>
                    <br />
                    <small className="fw-bold">
                      {pro.productId?.unitQuantityId.title}
                    </small>
                    <br />
                    <small className="fw-bold">Qty:{pro.quantity}</small>
                  </td>

                  <td>
                    {" "}
                    <span className="text-right text-cl-bl">
                      {" "}
                      ₹{pro.productId?.itemPrice}
                    </span>
                  </td>
                </tr>
              ))}
            </table>

            {/* <table className="order-total-price ">
              <tr>
                <td>Sub total </td>
                <td>
                  ₹{" "}
                  {orderInfo.productDetails
                    .map((pr) => pr?.productId?.itemPrice * pr?.quantity)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}
                </td>
                {orderInfo.totalPrice}</td>
              </tr>
              <tr className="text-muted">
                <td>Discount</td>
                <td className="text-danger">₹{discount}</td>
              </tr>

              <tr className="text-muted">
                <td>Gst</td>
                <td>₹ {gst}</td>
              </tr>
              <tr className="text-muted">
                <td>Delivery charge</td>
                <td>₹0</td>
              </tr>

              <tr className="grand">
                <td>
                  <b>Grand Total</b>
                </td>

                <td className="text-cl-bl">₹{orderInfo.totalPrice}</td>
              </tr>
            </table> */}
            {orderInfo.paymentMode === "COD" ? (
              <Box sx={{ mt: 2, mb: 2 }}>
                <h6>
                  <b>Payment Method</b>
                </h6>
                <div className="alert alert-success">Cash on delivery</div>
              </Box>
            ) : (
              ""
            )}
            {/* {showAction()} */}
            {console.log(orderInfo?.orderStatus, "dddfdsfdsfbegndse")}
           {orderInfo?.orderStatus == "PENDING" ?
        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            variant="contained"
            onClick={(e) => cancelOrder(orderInfo._id)}
          >
            Cancel Order
          </Button>
        </Box>
        : null
    }
          </Box>
          <div className="order-total-price order-total-price-catr ">
          <table>
              <tr>
                <td>Sub total </td>
                <td>
                  ₹{" "}
                  {orderInfo?.productDetails
                    .map((pr) => pr?.productId?.itemPrice * pr?.quantity)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}
                </td>
                {/* {orderInfo.totalPrice}</td> */}
              </tr>
              <tr className="text-muted">
                <td>Discount</td>
                <td className="text-danger">₹{discount}</td>
              </tr>

              <tr className="text-muted">
                <td>Gst</td>
                <td>₹ {gst}</td>
              </tr>
              <tr className="text-muted">
                <td>Delivery charge</td>
                <td>₹0</td>
              </tr>

              <tr className="grand">
                <td>
                  <b>Grand Total</b>
                </td>

                <td className="text-cl-bl">₹{orderInfo?.totalPrice}</td>
              </tr>
            </table>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDetails;
