import axios from "axios";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { StateContext } from "../context/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
//import geolocation from "geocodeapi";
import LoginPopup from "../auth/Login";
import RegisterPopup from "../auth/Register";
import OtpPopup from "./OtpPopup";
import MobilePopup from "./MobilePopup";
import "../compenent/NavBarTop.css";
// import "./navbar.css";
//import { API } from "../config/api";
import PasswordResetPopup from "./PasswordResetPopup";
//import { Alert } from "@mui/material";
import NavBarTop2 from "./NavBarTop2";
import ClickAwayListener from "@mui/base/ClickAwayListener";
//import { GiHamburgerMenu } from "react-icons/gi";
import DrawerButton from "./Drawer/DrawerButton/DrawerButton";
import Drawer from "./Drawer/Drawer";
import { removeItemFromLocalStorage } from "../utils/localStorageActions";

const API_endpoint = `https://api.openweathermap.org/data/2.5/weather?`;
const API_key = `d0666bfe307719a88f6d23cbb14ff0df`;

const NavBarTop = () => {
  // const {
  //   state: { cart },
  //   dispatch,
  //   productDispatch,
  // } = CartState();
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [isActive, setIsActive] = useState("");
  const {
    cart,
    isOpen,
    setIsOpen,
    currentPage,
    setCurrentPage,
    auth,
    setAuth,
    setIsPinOpen,
    cityName,
  } = useContext(StateContext);
  // const [user, setUser] = useState({});
  const navigate = useNavigate();
  //const [latitude, setLatitude] = useState("");
  //const [longitude, setLongitude] = useState("");
  const [city, setCity] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [open, setOpen] = useState(false);

  console.log("city", city);

  const { setDistributorId, user, setUser, pincode } = useContext(StateContext);

  const getDistributor = async () => {
    await axios
      .get(`https://api.orbitmart.co.in/api/v1/auth/distributor/${pincode}`)
      .then((res) => {
        setDistributorId(res.data.data._id);
        window.sessionStorage.setItem("distributorId", res.data.data._id);
        //navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setDistributorId("");
        window.sessionStorage.removeItem("distributorId");
        navigate("/merchantNotFound");
        //alert( 'Merchant Not Available')
      });
  };

  const handleClick = (type) => {
    setIsActive(type);
  };

  const { productName, setProductName } = useContext(StateContext);

  useEffect(() => {
    //  let pin=localStorage.getItem('pincode');
    if (pincode !== undefined) {
      if (pincode.length === 6) {
        getDistributor();
        // getProduct();
      }
    }
    //eslint-disable-next-line
  }, [pincode]);
  // }, [pincode]);

  // const getProduct=async() => {

  //    await axios.get(`https://api.orbitmart.co.in/api/v1/product/getProduct/${searchText}`)
  //         .then((response) => {
  //             setAPIData(response.data.data);
  //         })
  //         .catch((err)=> {
  //           console.log(err);
  //         });
  // };
  // useEffect(()=>{getProduct()},[searchText])
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position.coords);
    });
    let latitude = 30.2841856;
    let longitude = 78.0500992;
    let finalAPIEndPoint = `${API_endpoint}lat=${latitude}&lon=${longitude}&appid=${API_key}`;
    axios.get(finalAPIEndPoint).then((response) => {
      setCity(response.data);
    });
  }, []);

  // let lati = 30.2841856;
  //   let longi = 78.0500992;
  //   geolocation.setApiKey("GOOGLE_API_KEY");
  //   geolocation
  //     .getAddress({ longitude: longi, latitude: lati })
  //     .then((address) => {
  //       debugger;
  //       console.log(address);
  //     });

  // function openLeftMenu() {
  //   //debugger;
  //   document.getElementById("sideButton").style.display = "none";
  //   document.getElementById("mySidebar").style.display = "block";
  //   document.getElementById("mySidebar").style.left = "0px";
  //   // document.getElementById("leftMenu").style.marginTop = "30px";
  // }

  // function closeLeftMenu() {
  //   //debugger;
  //   document.getElementById("mySidebar").style.display = "none";
  //   document.getElementById("sideButton").style.display = "block";
  // }
  // ====================get cart length===========
  //const config1 = { headers: { "Content-Type": "application/json" } };
  //const API1 = `https://api.orbitmart.co.in/api/v1/cart/fetchBy`;

  // const getShoppingCartData = async () => {
  //   await axios
  //     .get(`${API1}/62ea587e5988c24ef81b0e48`, config1)
  //     // .get(`${API}/${userId}`, config)
  //     .then((res) => setShoppingcart(res.data.data));
  // };
  // useEffect(() => {
  //   getShoppingCartData();
  // }, []);
  // const myuserId = window.localStorage.getItem("userId");
  // const mytoken = window.localStorage.getItem("token");
  // // useEffect(() => {
  // //   getProfile();
  // // }, [user]);

  // const getProfile = async () => {

  //   // if(auth)
  //   // {

  //    await axios
  //    .get(`${API}/auth/get-profile`, {
  //      headers: { "Content-Type": "application/json", "x-token": mytoken },
  //    })
  //    .then((res) => setUser(res.data));
  //   // }

  // };
  // getProfile()
  // ====================get cart length===========

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const page = () => {
    switch (currentPage) {
      case 1:
        return <RegisterPopup handleClose={togglePopup} />;
      case 2:
        return <OtpPopup handleClose={togglePopup} />;
      case 3:
        return <MobilePopup handleClose={togglePopup} />;
      case 4:
        return <PasswordResetPopup handleClose={togglePopup} />;
      default:
        return <LoginPopup handleClose={togglePopup} />;
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setUser([]);
    setAuth(false);
    removeItemFromLocalStorage("user");
    removeItemFromLocalStorage("token");
    navigate("/");
  };

  const convertSearchProduct = (str) => {
    //str=>veg manchurian

    let strA = str.split(" "); //['veg','manchurian']

    for (let i = 0; i < strA.length; i++) {
      let char = strA[i]; //i=0->veg i=1 =>manchurian

      let strnew = char.split("");
      strnew[0] = strnew[0].toUpperCase();
      strA[i] = strnew.join("");
    }

    return strA.join(" "); //Veg Manchurian
  };
  //=========================================================== pranit code
  const getProduct = async () => {
    let searchvalue = convertSearchProduct(productName);

    let payload = {
      itemName: searchvalue,
    };
    console.log(searchvalue);
    const config = { headers: { "Content-Type": "application/json" } };

    await axios
      .post(
        `https://api.orbitmart.co.in/api/v1/product/getProduct/${pincode}`,
        payload,
        config
      )
      .then((response) => {
        setSearchResult(response.data.data);
        setOpen(true);
        console.log("ok search", searchResult);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleClick = () => {
  //   setOpen((prev) => !prev);
  // };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div>
      {" "}
      <nav className="navbar navbar-expand-xl navbar-dark  fixed-top firstnavnavbar-light">
        <Link className="navbar-brand logimg p-2 pb-2" to="/">
          <img src="/assets/images/orbitlog.png" alt="reload it" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavTop"
          aria-controls="navbarNavTop"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse  " id="navbarNavTop">
          <div className="navbar-nav  bg-navy ">
            {/* <div
                className="nav-item "
              >
           
                
              </div> */}

            <div className="nav-item  ">
              <div className=" d-flex ">
                {/* <div className="form-group border-none mb-3 mt-3 locico">
                <i className="fa fa-compass" aria-hidden="true"></i>
              </div> */}
                <div className="icons pe-3">
                  <div className=" d-flex text-white p-2">
                    <small>
                      <b className="ps-1 pe-1">{cityName} </b>
                    </small>
                    <i
                      className="fa fa-map-marker text-white"
                      aria-hidden="true"
                      // style={{ marginTop: "rem", marginLeft: "95px" }}
                    >
                      {""}
                    </i>
                    <small>
                      <b className="ps-1">{pincode}</b>
                    </small>
                    <i
                      className="fa fa-pencil ps-2 text-white"
                      style={{ marginLeft: "auto" }}
                      onClick={() => setIsPinOpen(true)}
                      aria-hidden="true"
                    ></i>

                    {/* <input
                    type="text"
                    className="pincdno"
                    placeholder="Pincode"
                    maxLength={6}
                    onChange={(e) => {
                      setPincode(e.target.value);
                     
                    }}
                    
                    // value={pincode}
                    // style={{height:"49.5px",marginBottom:"21px"}}
                  /> */}
                  </div>
                </div>
                {/*=========================================================================== pranit code */}
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div
                    // style={{ width: "600px" }}
                    className="input-group "
                  >
                    <input
                      type="text"
                      className="cust-srch-input"
                      placeholder="Search products"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      value={productName}
                      onChange={(e) => {
                        setProductName(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="cust-srch-btn"
                        type="button"
                        id="button-addon2"
                        // style={{marginLeft:"300px"}}
                        onClick={getProduct}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                    {/* <div className="input-group-append">
                  <button
                    className="cust-search-botton"
                    type="button"
                    id="button-addon2"
                    onClick={getProduct}
                    // style={{marginLeft:"300px"}}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div> */}
                    {searchResult.length > 0 && open && (
                      <div
                        className="scrolleffect d-flex flex-column bd-highlight mb-3 overflow-auto d-inline-block scrollbar scrollbar-lady-lips"
                        style={{
                          position: "absolute",
                          top: "55px",
                          background: "#003C7E",
                          width: "500px",
                          maxHeight: "450px",
                          zIndex: "100",
                        }}
                      >
                        {searchResult.map((data, idx) => {
                          return (
                            <div
                              className="text-white p-2 bd-highlight border-bottom font-weight-normal hover-overlay"
                              style={{
                                justifyContent: "space-around",
                                display: "flex",
                              }}
                            >
                              <div>
                                <img
                                  src={data.image}
                                  style={{
                                    width: "50px",
                                    height: "60px",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div>{data.itemName}</div>
                              <div>{data.customerPrice}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </div>
            <div className="nav-item font-weight-normal p-2">
              {/* active font-weight-normal shopico ms-auto */}
              <div className={`icons cursor-pointer ${isActive === "cart" ? "active" : ""}`}
                    onClick={() => handleClick("cart")}>
                <Link to="/shoppingcart" className="">
                  <i
                    className="fa fa-shopping-cart text-white "
                    aria-hidden="true"
                  >
                    <small>
                      <b className="ps-1">My cart</b>
                    </small>
                    <sup className="text-white  mr-auto p-2 ">
                      {cart.length !== 0 && cart.length}
                    </sup>
                  </i>
                </Link>
              </div>
            </div>
            {auth ? (
              <Fragment>
                {/* <div className="nav-item font-weight-normal p-2">
                  <div className="icons">
                    <Link to="/notification" className="">
                      <i
                        className="fa fa-solid fa-bell text-right text-white "
                        style={{ fontSize: "16px" }}
                        aria-hidden="true"
                      >
                        {" "}
                        <small>
                          <b className="ps-1">Notification</b>
                        </small>
                      </i>
                    </Link>
                  </div>
                </div> */}
                <div className="nav-item font-weight-normal p-2">
                  <div
                    className={`icons cursor-pointer ${isActive === "order" ? "active" : ""}`}
                    onClick={(e) => {
                      handleClick("order")
                      navigate("/myorder")}}
                  >
                    <i
                      className="fa fa-first-order text-right text-white fa-md"
                      aria-hidden="true"
                    >
                      <small>
                        <b className="ps-1">My Order</b>
                      </small>
                    </i>
                  </div>
                </div>
                <div className="nav-item font-weight-normal p-2">
                  <div
                     className={`icons cursor-pointer ${isActive === user?.data?.firstName ? "active" : ""}`}
                     onClick={(e) => {
                       handleClick(user?.data?.firstName)
                      navigate("/myprofile/delete-account")}}
                  >
                    <i
                      className="fa fa-user-o  text-white fa-md"
                      aria-hidden="true"
                    >
                      <small>
                        <b className="ps-1">{user?.data?.firstName}</b>
                      </small>
                    </i>
                  </div>
                </div>
                <div className="nav-item font-weight-normal p-2">
                  <div
                    className={`icons cursor-pointer ${isActive === "Logout" ? "active" : ""}`}
                    onClick={(e) =>{ 
                      handleClick("Logout")
                      handleLogout(e)}}
                  >
                    <i
                      className="fa fa-sign-out text-right text-white fa-md"
                      aria-hidden="true"
                    >
                      <small>
                        <b className="ps-1">Logout</b>
                      </small>
                    </i>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="nav-item font-weight-normal p-2">
                <div
                  className={`icons cursor-pointer ${isActive ? "active" : ""}`}
                  onClick={(e) => {
                    handleClick()
                    setCurrentPage(0);
                    togglePopup();
                  }}
                >
                  <i
                    className="fa fa-sign-in text-right text-white fa-md"
                    aria-hidden="true"
                  >
                    <small>
                      <b className="ps-1">Login</b>
                    </small>
                  </i>
                </div>
              </div>
            )}
            <DrawerButton onClick={() => setIsOpenDrawer(!isOpenDrawer)} />
            <Drawer isOpen={isOpenDrawer} setIsOpenDrawer={setIsOpenDrawer} />

            {isOpen && page()}
          </div>
        </div>
      </nav>
      {/* <NavBarTop2 /> */}
    </div>
  );
};

export default NavBarTop;
