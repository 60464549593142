import React from 'react';
import './termsCondition.css';

const TermsCondition = () => {
	return (
		<div className="Terms_conditions_container mx-auto w-75 bs crd" style={{marginTop: '110px'}}>
			{/* <div className="card mx-5 text-justify ">
				<h4 className="text-center text-black pt-3">Terms and Conditions</h4>
				<h5 className="text-center py-3">Growup Sindhu Technology & Solutions Pvt. Ltd.</h5>
				<h6 className="text-center pb-2">
					Website - <b className="text-lowercase">www.orbitmarts.com</b>
				</h6>
				<h6 className="General_terms_condition_heading text-left py-3">General Terms & Conditions:</h6>
				<p>
					<small className="py-5">
						This document is an electronic record in terms of Information Technology Act, 2000 and rules
						there under as applicable and the amended provisions pertaining to electronic records in various
						statutes as amended by the Information Technology Act, 2000. This electronic record is generated
						by a computer system and does not require any physical or digital signatures.
					</small>
					<br />
					<br />
					<small className="py-5">
						This document is published in accordance with the provisions of Rule 3 (1) of the Information
						Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
						regulations, privacy policy and Terms of Use for access or usage of www.orbitmarts.com website{' '}
					</small>
					<br />
					<br />
					<small className="py-5">
						The domain name www.orbitmarts.com (hereinafter referred to as "Website") is owned by Growup
						Sindhu Technology & solutions Private Limited a company incorporated under the Companies Act,
						1956 with its registered office at H No.3158 Shivshakti-2, Near Kudaleshwar Mandir, Kudal, Dist-
						Sindhudurg, Maharashtra-416520.India. Your use of the Website and services and tools are
						governed by the following terms and conditions ("Terms of Use") as applicable to the Website
						including the applicable policies which are incorporated herein by way of reference. If You
						transact on the Website, you shall be subject to the policies that are applicable to the Website
						for such transaction. By mere use of the Website, you shall be contracting with Growup Sindhu
						Technology & Solutions Private Limited and these terms and conditions including the policies
						constitute Your binding obligations. For the purpose of these Terms of Use, wherever the context
						so requires "You" or "User" shall mean any natural or legal person who has agreed to become a
						Customer on the Website by providing Registration Data while registering on the Website as
						Registered User using the computer systems. Orbit Mart allows the User to surf the Website or
						making purchases without registering on the Website. The term "We", "Us", "Our" shall mean
						Growup Sindhu Technology & Solutions Private Limited.
					</small>
					<br />
					<br />
					<small className="py-5">
						When You use any of the services provided by Us through the Website, including but not limited
						to, (e.g., Product Reviews, Merchant Reviews), You will be subject to the rules, guidelines,
						policies, terms, and conditions applicable to such service, and they shall be deemed to be
						incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of
						Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of
						these Terms of Use, at any time without any prior written notice to You. It is Your
						responsibility to review these Terms of Use periodically for updates / changes. Your continued
						use of the Website following the posting of changes will mean that You accept and agree to the
						revisions. As long as You comply with these Terms of Use, we grants You a personal,
						non-exclusive, nontransferable, limited privilege to enter and use the Website.
					</small>
					<br />
					<br />
					<small>
						<b className="Terms_conditions_bolded_content py-5">
							ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS
							AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE
							PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and
							agree to be bound by Orbit Mart Policies as amended from time to time.
						</b>
					</small>
				</p>
			</div> */}
			<div>
				<h1>Terms & Conditions</h1>
				<h2>Effective Date: <span style={{color: '#2e323c', fontSize: '16px', fontWeight: '500'}}> 20 October 2020</span></h2>
			</div>
			<div>
				<p>
				Welcome to Orbit Mart! These Terms and Conditions ("Terms") govern your use of the Orbit Mart mobile application
				(the "App"), which is available for download through the Google Play Store and used for online shopping. 
				By using, accessing, or downloading the App, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the App.
				<br/>
				<br/>
                Please read these Terms carefully before using the Orbit Mart mobile application.
				</p>
			</div>
			<div className='points-tc'>
               <h2>1. Acceptance of Terms</h2>
			   <p>By downloading, installing, or using the Orbit Mart App, you acknowledge that you have read, understood, and agree to these Terms and Conditions, as well as any updates or modifications that may be made from time to time. We may update these Terms at any time, and your continued use of the App constitutes acceptance of the revised Terms.</p>

			   <h2>2. Eligibility</h2>
			   <p>To use the App, you must be at least 18 years old or the age of majority in your jurisdiction, whichever is greater. If you are under 18, you may only use the App with the involvement of a parent or guardian.</p>
			   
			   <h2>3. Account Registration</h2>
			   <p>
			   &#8226; You may be required to create an account to use certain features of the App. When registering, you agree to provide accurate, current, and complete information and maintain the security of your account by keeping your login credentials confidential.
				<br />
				<br />
				&#8226; You are responsible for all activities that occur under your account and agree to notify us immediately if you believe your account has been compromised.
			   </p>

			   <h2>4. Use of the App</h2>
			   <p>
			   &#8226; You agree to use the App only for lawful purposes and in accordance with these Terms.
			   <br />
				<br />
			   &#8226; You must not use the App in any way that could damage, disable, overburden, or impair the functionality of the App or interfere with other users' enjoyment of the App.
			   <br />
				<br />
			   &#8226; You agree not to engage in any fraudulent or illegal activities through the App.
			   </p>

			   <h2>5. User Conten</h2>
			   <p>
			   &#8226; You may upload, post, or transmit content to the App, including reviews, product feedback, and comments ("User Content").
			   <br />
			   <br />
			   &#8226; You retain ownership of your User Content, but by submitting it to Orbit Mart, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, reproduce, distribute, and display it in connection with the operation of the App and marketing of the App.
			   <br />
			   <br />
&#8226; You agree not to post or transmit any content that is illegal, defamatory, obscene, offensive, or infringes upon the rights of others.
			   </p>

			   <h2>6. Privacy Policy</h2>
               <p>We value your privacy and take your personal information seriously. Our Privacy Policy explains how we collect, use, and protect your data when you use the App. By using the App, you consent to the collection and use of your personal information as described in the Privacy Policy.</p>
			   
			   <h2>7. Product Availability and Pricing</h2>
			   <p>
			   &#8226; All products listed on the App are subject to availability. We reserve the right to limit quantities of items or discontinue products at any time without notice.
			   <br />
			   <br />
			   &#8226; Prices for products may change without prior notice. In the event of a pricing error, we reserve the right to cancel or refuse orders at the incorrect price.
			   </p>

			   <h2>8. Payment and Transactions</h2>
			   <p>
			   &#8226; Payments for products and services offered through the App are processed through secure third-party payment providers. By using the App, you agree to abide by the terms of service of any third-party payment processors.
			   <br />
				<br />
			   &#8226; You are responsible for providing accurate billing information and ensuring sufficient funds are available to complete your transactions.
			   </p>

			   <h2>9. Delivery and Shipping</h2>
			   <p>
			   &#8226; Shipping and delivery times may vary based on location and product availability. We will provide estimated delivery times during the checkout process.
			   <br />
			   <br />
			   &#8226; You are responsible for providing accurate shipping information. We are not liable for delays or issues resulting from incorrect shipping details provided by you.
			   </p>

			   <h2>10. Intellectual Property</h2>
			   <p>
			   &#8226; All content within the App, including logos, trademarks, images, text, software, and designs, is owned by Orbit Mart or its licensors and is protected by copyright, trademark, and other intellectual property laws.
			   <br />
			   <br />
			   &#8226; You may not use, reproduce, modify, distribute, or display any of our intellectual property without prior written permission.
			   </p>

			   <h2>11. Prohibited Activities</h2>
			   <p>
			   You agree not to:
			   <br />
&#8226; Violate any laws or regulations.
<br />
				<br />
&#8226; Attempt to reverse-engineer, decompile, or otherwise access the source code of the App.
<br />
				<br />
&#8226; Use the App for unauthorized commercial purposes.
<br />
				<br />
&#8226; Interfere with the operation of the App or any user’s ability to access and use the App.
			   </p>

			   <h2>12. Limitation of Liability</h2>
			   <p>
			   To the fullest extent permitted by law, Orbit Mart shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the App, including, without limitation, damages for loss of profits, goodwill, data, or other intangible losses.
			   </p>

			   <h2>13. Termination</h2>
			   <p>
			   We reserve the right to suspend or terminate your access to the App at any time, without notice, if we believe you have violated these Terms or engaged in any unlawful activity. Upon termination, your right to access the App will immediately cease.
			   </p>

			   <h2>14. Third-Party Links</h2>
			   <p>
			   The App may contain links to third-party websites or services that are not owned or controlled by Orbit Mart. We are not responsible for the content or privacy practices of third-party sites and encourage you to review their terms and policies.
			   </p>

			   <h2>15. Governing Law</h2>
			   <p>
			   These Terms and Conditions are governed by the laws of [Your Country/State], without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms will be resolved in the competent courts of [Your Jurisdiction].
			   </p>

			   <h2>16. Dispute Resolution</h2>
			   <p>
			   In the event of a dispute, we encourage you to first contact us at [contact email or phone number] to attempt to resolve the matter. If a resolution cannot be reached, you agree to submit the dispute to binding arbitration in accordance with the rules of [arbitration body].
			   </p>

			   <h2>17. Miscellaneous</h2>
			   <p>
			   &#8226; If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full effect.
			   <br />
				<br />
			   &#8226; These Terms represent the entire agreement between you and Orbit Mart regarding the use of the App.
			   </p>
			</div>
			<div>
				<h2>Contact Us:</h2>
				<p>
				If you have any questions about these Terms and Conditions, please contact us at:
				<br/>
				&#8226; Email: orbitmarthelpdesk@gmail.com
<br />
				<br />
				&#8226; Company Email: orbitmartindia@gmail.com
<br />
				<br /> 
				&#8226; Address: Shivashakti complex building no.2  F1/F2 
Near kudaleshwar mandir, kudaleshwar wadi, shivaji nagar, Tal.Kudal 
Dis.Sindhudurg 
Maharashtra
<br />
				<br />
				&#8226; Phone: +919007957009
				<br />
By using the Orbit Mart App, you confirm that you have read and agree to these Terms and Conditions.
				</p>
				<br />
				<br />
				<p>
				This template should cover the essential points needed for a Terms and Conditions page for an eCommerce mobile app on the Play Store. Make sure to customize the placeholders with your actual information (e.g., effective date, contact details, jurisdiction, etc.) and consider consulting with a legal professional to ensure compliance with applicable laws and regulations.
				</p>
			</div>
		</div>
	);
};

export default TermsCondition;