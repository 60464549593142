import React from 'react'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './NewCard.css'
import FormControl from '@mui/material/FormControl';
import { TextField } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea } from '@mui/material';
import Img from '../assets/images/glucose.jpg'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

const OrderHistory = () => {

  const [age, setAge] = React.useState('');



  //   const handleChange = (event: SelectChangeEvent) => {
  //     setAge(event.target.value as string);
  //   };




  return (



    <>

      <div className="Order-sum">
        <div style={{ marginLeft: "500px" }}><Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom component="div">
          Order History
        </Typography></div>
        <div style={{ marginTop: "5px" }}>    <TextField className="Text"
          variant="outlined"
          id="outlined-size-small"
          placeholder="Search all orders"
          size="small"
        // sx={{width:50 !important}}

        ></TextField></div>
        <div className="butt">    <Button sx={{ width: 150, height: 30, borderRadius: 1, color: 'black' }} variant="contained" size="small">
          Search Order
        </Button></div>






      </div>
      {/*          
        <div className="Tabs">  <Typography variant="p" sx={{ fontWeight: "bold", display: "block" }} >    <Link to="/Order History"> Order History</Link> 
		</Typography>
          <Typography varaint="p" sx={{pl:3, fontWeight: "bold"}} >Cancel Order</Typography>


</div> */}
      <div style={{ display: "flex", justifyContent: "center", gap: 10 }} >
        {/* <Button  component={Link} to="/about" variant="outlined"  color="primary">
  Order History
          </Button> */}
        <Button component={Link} to="/CancelOrder" variant="outlined" color="primary">
          Cancel Order
        </Button>
        <Button component={Link} to="/MyOrder" variant="outlined" color="primary">
          My Order
        </Button>
      </div>

      <hr className="new2"></hr>

      <div className="dropdown">
        <Typography variant="p" sx={{ fontWeight: "bold", marginTop: "15px" }} gutterBottom component="div">
          One Order Placed
        </Typography>
        {/* <div style={{marginLeft:"30px", marginTop:"-4px"}}>

                      <FormControl sx={{ width:120}} >
        <InputLabel  id="demo-simple-select-label">Orders</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
        //   onChange={handleChange}   
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
                </div>
                 */}

      </div>
      <div className="cord">
        <Card sx={{ width: 900, boxShadow: "0 1px 2px 1px lightGrey   ", border: "1px  gray", borderRadius: 3 }}>
          <CardActionArea>
            {/**/}

            <div className="Content">
              <CardContent>

                <Typography variant="p" sx={{ fontSize: 13, fontWeight: "bold" }} color="text.secondary">
                  ORDER PLACED
                </Typography>

                <Typography variant="p" sx={{ fontSize: 13, fontWeight: "bold", pl: 5 }} color="text.secondary">
                  TOTAL
                </Typography>
                <Typography variant="p" sx={{ fontSize: 13, fontWeight: "bold", pl: 5 }} color="text.secondary">
                  DELIVERY ADDRESS
                </Typography>
                <Typography variant="p" sx={{ fontSize: 13, fontWeight: "bold", pl: "330px" }} color="text.secondary">
                  ORDER #932975277570120381
                </Typography>
              </CardContent>
            </div>

            <div className="Content2">

              <CardContent>

                <Typography variant="p" sx={{ fontSize: 13 }} color="text.secondary">
                  19 JULY 2022
                </Typography>

                <Typography variant="p" sx={{ fontSize: 13, pl: 7 }} color="text.secondary">
                  $5
                </Typography>
                <Typography variant="p" sx={{ fontSize: 13, pl: 7.6 }} color="text.secondary">
                  Deepesh
                </Typography>
                <Typography variant="p" sx={{ fontSize: 13, pl: "390px" }} color="text.secondary">
                  View Order Details
                </Typography>

                <Typography variant="p" sx={{ fontSize: 13, pl: 4 }} color="text.secondary">
                  Invoice
                </Typography>
              </CardContent>
            </div>
            <hr className="new1"></hr>
            <div className="delivery">
              <Typography gutterBottom variant="p" sx={{ fontWeight: "bold", pl: 2 }} component="div">
                Delivery , Thursday JULY 2022
              </Typography>
              <div style={{ paddingRight: "20px" }}>
                {/* <Button variant="contained"> View Order</Button> */}
                <Button sx={{ width: 150, borderRadius: 1 }} variant="contained" size="small">
                  Orders
                </Button>

              </div>
            </div>
            <div style={{ width: '80px', marginLeft: "30px", marginTop: "30px", height: "70px" }}>
              <CardMedia
                component="img"
                height="80"
                image={Img}
                alt="product"
              />
            </div>

            <div style={{ height: "50px", marginTop: "40px" }}>
              <Typography gutterBottom variant="p" sx={{ fontWeight: "bold", pl: 2, width: 300 }} component="div">
                1 item in this order
              </Typography>

            </div>


          </CardActionArea>
        </Card>
      </div>

    </>

  )
}

export default OrderHistory;
