import React from 'react'
import './notification.css'

const MyNotification = () => {
    return (
      <div className="mx-auto ms-3 crd bg-white rounded main-container-notified">
        <div className="px-5 pb-5 container d-block justify-content-center ">
      <div className=" mx-auto pb-3 img-thumbnail w-25 h-25 text-center ">
        <h5 style={{ marginTop: "10px" }}>We're sorry</h5>
   

      <div className="text-center  ">

        <div className="row ">
        <h6>Notification Not Found</h6>
          <img src="https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-87-512.png" alt="notification not found"  style={{margin:"auto"}} />
        </div>
      </div>
      </div>
    </div>

        <div className="notification-sec">
          <div className="inner-sec-noti">
            <div className="heder-box">
              <h1>All Notifications</h1>
            </div>
            <div>
              <div className="cart-noti">
                <div className="inner-cart-noti">
                  <div className='up-noti-catr'>
                    <div className="cart-noti-img">
                      <img
                        src="https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-87-512.png"
                        alt="notification"
                      />
                    </div>
                    <div className="cart-noti-text">
                      <h5>New Order</h5>
                      <p>You have a new order from Samso aliao</p>
                    </div>
                  </div>
                  <div className='right-para'>
                    <p>View</p>
                  </div>
                </div>
                <div className="inner-cart-noti">
                  <div className='up-noti-catr'>
                    <div className="cart-noti-img">
                      <img
                        src="https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-87-512.png"
                        alt="notification"
                      />
                    </div>
                    <div className="cart-noti-text">
                      <h5>New Order</h5>
                      <p>You have a new order from Samso aliao</p>
                    </div>
                  </div>
                  <div className='right-para'>
                    <p>View</p>
                  </div>
                </div>
                <div className="inner-cart-noti">
                  <div className='up-noti-catr'>
                    <div className="cart-noti-img">
                      <img
                        src="https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-87-512.png"
                        alt="notification"
                      />
                    </div>
                    <div className="cart-noti-text">
                      <h5>New Order</h5>
                      <p>You have a new order from Samso aliao</p>
                    </div>
                  </div>
                  <div className='right-para'>
                    <p>View</p>
                  </div>
                </div>
                <div className="inner-cart-noti">
                  <div className='up-noti-catr'>
                    <div className="cart-noti-img">
                      <img
                        src="https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-87-512.png"
                        alt="notification"
                      />
                    </div>
                    <div className="cart-noti-text">
                      <h5>New Order</h5>
                      <p>You have a new order from Samso aliao</p>
                    </div>
                  </div>
                  <div className='right-para'>
                    <p>View</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <>
      //     <div className="container d-block justify-content-center">
      //         <h2 className='mb-4'>My Notifications</h2>
      // <div className="shadow p-2 mb-3 bg-body rounded">
      //     <div className="m-0 px-3">
      //         <h5 className='m-0'>Samso aliao</h5>
      //         <p className='m-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi aspernatur, rem laboriosam perspiciatis labore?</p>
      //         <p className='fw-light fs-6 text-end m-0'>just now</p>
      //     </div>
      // </div>
      //         <div className="shadow p-2 mb-3 bg-body rounded">
      //             <div className="m-0 px-3">
      //                 <h5 className='m-0'>John Silvester</h5>
      //                 <p className="m-0">+20 Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, dolores.</p>
      //                 <p className='fw-light fs-6 text-end m-0'>just now</p>
      //             </div>
      //         </div>
      //     </div>
      // </>
    );
}

export default MyNotification