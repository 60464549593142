import React, { createContext, useState } from "react";
import { getItemsFromLocalStorage } from "../utils/localStorageActions";
import { useEffect } from "react";

export const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [userId, setUserId] = useState("");
  const [auth, setAuth] = useState(false);
  const [register, setRegister] = useState(false);
  const [domainTypeN, setDomainTypeN] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [shoppingcart, setShoppingcart] = useState([]);
  const [domainTypeL, setDomainTypeL] = useState([]);
  const [categoryL, setCategoryL] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [groupcategoryId, setGroupCategoryId] = useState("");
  const [groupcategoryProduct, setGroupCategoryProduct] = useState([]);
  const [groupcategoryL, setGroupCategoryL] = useState([]);
  const [groceryMerchant, setGroceryMerchant] = useState(false);
  const [pincode, setPincode] = useState(
    localStorage.getItem("pincode") ? localStorage.getItem("pincode") : ""
  );
  const [cityName, setCityName] = useState("");
  // const [pincode, setPincode] = useState(localStorage.getItem('pincode')?localStorage.getItem('pincode'):'');
  const [merproductcategory, setmerproductcategory] = useState([]);
  // const [distributorId, setDistributorId] = useState('')
  const [distributorId, setDistributorId] = useState(
    sessionStorage.getItem("distributorId")
      ? sessionStorage.getItem("distributorId")
      : ""
  );
  const [offers, setOffers] = useState([]);
  const [cart, setCart] = useState(getItemsFromLocalStorage("cart") || []);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); //0-login,1-register,2-otp
  const [user, setUser] = useState(getItemsFromLocalStorage("user") || null);
  const [registerInfo, setRegisterInfo] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [groceryId, setGroceryId] = useState("");
  const [isPinOpen, setIsPinOpen] = useState(false);
  const [merchantGST, setMerchantGST] = useState(0);
  const [myOrder, setMyOrder] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  const [productName, setProductName] = useState("");
  // const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState([]);
  // const [state, dispatch] = useReducer(cartReducer, {
  //   cart: [],
  // });
  const [recommended, setRecommended] = useState([]);

  //for reacting on changing of user state
  //changing the auth status
  useEffect(() => {
    if (user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [user]);

  return (
    <StateContext.Provider
      value={{
        auth,
        setAuth,
        userId,
        setUserId,
        register,
        setRegister,
        domainTypeN,
        setDomainTypeN,
        merchantList,
        setMerchantList,
        productList,
        setProductList,
        domainTypeL,
        setDomainTypeL,
        categoryL,
        setCategoryL,
        categoryId,
        setCategoryId,
        categoryProduct,
        setCategoryProduct,
        groupcategoryId,
        setGroupCategoryId,
        groupcategoryProduct,
        setGroupCategoryProduct,
        groupcategoryL,
        setGroupCategoryL,
        groceryId,
        setGroceryId,
        cart,
        setCart,
        // // state,
        // // dispatch,
        shoppingcart,
        setShoppingcart,
        recommended,
        setRecommended,
        pincode,
        setPincode,
        distributorId,
        setDistributorId,
        offers,
        setOffers,
        isOpen,
        setIsOpen,
        currentPage,
        setCurrentPage,
        registerInfo,
        setRegisterInfo,
        user,
        setUser,
        merproductcategory,
        setmerproductcategory,
        isPinOpen,
        setIsPinOpen,
        redirect,
        setRedirect,
        merchantGST,
        setMerchantGST,
        myOrder,
        setMyOrder,
        orderInfo,
        setOrderInfo,
        productName,
        setProductName,
        products,
        setProducts,
        groceryMerchant,
        setGroceryMerchant,
        cityName,
        setCityName,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
// export const CartState = () => {
//   return useContext(StateContext);
// };
