import React, { useContext, useState } from "react";
import "./pay.css";
import card from "../assets/images/pranit.png";
import R from "../assets/images/R.jpg";
import Gpay from "../assets/images/Gpay.png";
import phonepe from "../assets/images/phonepe.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { showSpinner } from "../compenent/helpers/loading";
import { StateContext } from "../context/ContextProvider";
import { API } from "../config/api";
import { getItemsFromLocalStorage } from "../utils/localStorageActions";

export default function PaymentMethod() {
  const { orderInfo, setOrderInfo } = useContext(StateContext);

  const navigate = useNavigate();
  const [payment, setPayment] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
  };

  console.log("payment", payment);

  const CheckoutHandler = async (e) => {
    e.preventDefault();

    console.log("orderInfo", orderInfo);
    const updatedOrderInfo = {
      ...orderInfo,
      paymentMode: "COD",
      paymentStatus: "COD",
    };

    console.log("updatedOrderInfo", updatedOrderInfo);

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-token": getItemsFromLocalStorage("token"),
      },
    };

    setLoading(true);
    try {
      const res = await axios.put(
        `${API}/order/${orderInfo._id}`,
        updatedOrderInfo,
        config
      );

      console.log(res.data.data);
      const getRes = await axios.get(`${API}/order/${orderInfo._id}`, config);

      setOrderInfo(getRes.data.data);
      navigate("/OrderDetails");
    } catch (err) {
      console.log("order err", err);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <form onSubmit={CheckoutHandler}>
        <div className="row">
          {loading && showSpinner()}
          <div className="col">
            <h3 className="title">payment</h3>
            <div>
              We are taking <b>Cash on Delivery </b>right now
            </div>
            <div className="inputBox">
              <span>cards accepted :</span>
              <img src={card} alt="" />
            </div>
            <div className="inputBox">
              <span>card holder name :</span>
              <input type="text" placeholder="mr. pranit" disabled />
            </div>
            <div className="inputBox">
              <span>Enter card number :</span>
              <input type="number" placeholder="1111-2222-3333-4444" disabled />
            </div>
            <div className="inputBox">
              <span>exp month :</span>
              <input type="text" placeholder="MM-YY" disabled />
            </div>

            <div className="flex">
              <div className="inputBox">
                <span>exp year :</span>
                <input type="number" placeholder="2022" disabled />
              </div>
              <div className="inputBox">
                <span>CVV :</span>
                <input type="text" placeholder="1234" disabled />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />

          <div className="relative z-0 p-2 w-full mb-6 group">
            <div className="inputBox">
              <span>UPI accepted :</span>
              <br />
              <img height="50px" width="70px" src={R} alt="" />
            </div>
            <br />
            <div className="d-inline">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionvalue"
                  id="flexRadioDefault1"
                  onChange={handlePaymentChange}
                  value="Gpay"
                  disabled
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  <img height="15px" width="15px" src={Gpay} alt="Gpay" /> Gpay
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionvalue"
                  id="flexRadioDefault2"
                  onChange={handlePaymentChange}
                  value="phonepe"
                  disabled
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  <img height="30px" width="30px" src={phonepe} alt="PhonePe" />
                  PhonePe
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="optionvalue"
                  id="flexRadioDefault3"
                  onChange={handlePaymentChange}
                  value="COD"
                />
                <label className="form-check-label" for="flexRadioDefault3">
                  Cash On Delivery
                </label>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          classname="submit-btn"
          style={{
            margin: "25px 0px",
            backgroundColor: "#4CAF50",
            padding: "25px 220px",
            borderRadius: "8px",
            textAlign: "center",
            fontSize: "20px",
            color: "white",
          }}
          disabled={payment ? false : true}
        >
          Proceed to checkout
        </button>
      </form>
    </div>
  );
}
