import React,{useState,useContext, useEffect} from 'react'
import Draggable from 'react-draggable';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { StateContext } from '../context/ContextProvider';
import axios from 'axios';
import { API } from '../config/api'
import { showErrorMsg } from "./helpers/message";
import { showLoader } from "../compenent/helpers/loading";
const OtpPopup = (props) => {

   const {registerInfo,setCurrentPage,setRegisterInfo} = useContext(StateContext);
   const [errMsg,setErrMsg]=useState(false)
   const [loading, setLoading] = useState(false);
//   const [user,setUser]=useState([])
//   useEffect(async()=>{
// await axios.get(`${API}/auth/byDistributor/${registerID}`).then(res=>setUser(res.data))
//   },[])
var timeout;


  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  }

// const showErrorMessage = (msg) => {
//   return (
//     <div className="alert alert-danger" role="alert">
//  {msg}
//   </div>
//   )
// }
   console.log(registerInfo)
  const [otpData, setOtpData] = useState({
     otp1: "", otp2: "", otp3: "", otp4: ""
  });

  const {  otp1, otp2, otp3, otp4} =otpData;
  // const handleChange=(value1, event)=> {

  //   setOtpData({ [value1]: event.target.value });
  // }
const resendOTP=async()=>{
  setErrMsg(false)
 await axios.get(`${API}/auth/resend/otp/${registerInfo.mobile}`).then(res=>
  setRegisterInfo({otp:res.data.data,
    mobile:registerInfo.mobile}))
}
console.log(registerInfo.otp)
  const handleChange = (e) => {
    const {value}=e.target;

    if(validNumber(value)||e.key === "Backspace"){
      setOtpData({
       ...otpData,
      [e.target.name]: e.target.value,
       })
      }
  };
const validNumber=(num)=>{

  const numberRegEx = /\-?\d*\.?\d{1,2}/;
  return numberRegEx.test(String(num).toLowerCase());
  
}
  const handleSubmit=async(event)=> {
    
    event.preventDefault();
    setLoading(true);
    let otp=otp1+otp2+otp3+otp4

    if(otp==registerInfo.otp){
      const config={headers:{"Content-Type":"application/json"}}
      let data={
        mobile: parseInt(registerInfo.mobile),
        otp: registerInfo.otp.toString()
      }
      
      await axios.post(`${API}/auth/verify-mobile`,data,config).then((res)=>{
        // console.log(res)
        setLoading(false)
        setCurrentPage(0)

      })
    
      }
      // if(user.data.otp==otp)
     else{
      setLoading(false)
      setErrMsg(true)
      
      // setMsg(res.data.message);
timeout = displayErrMsg(setErrMsg, timeout);
}
    
  }

  const inputFocus = (elmnt) => {
    // elmnt.key === "Delete" ||
    if (elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
       
      }
      setOtpData({
        ...otpData,
       [elmnt.target.name]: "",
        })
    }
    else {
      // console.log("next");
        if(validNumber(elmnt.target.value))
        {
          const next = elmnt.target.tabIndex;
          if (next < 4) {
            elmnt.target.form.elements[next].focus()
          }
        }
       
    }

  }

  return (
    <div className="popup-box">
         
                {errMsg && showErrorMsg("Invalid OTP", errMsg)}
        <Draggable>
     <div>
    <div className="box">
      <span className="close-icon" onClick={props.handleClose}>
        x
      </span>
      <div className=" d-flex w-100">
        <Grid container component="main">
          <CssBaseline />
          <Grid
            item
            sm={4}
            component={Paper}
            elevation={6}
            sx={{ backgroundColor: "#003C7E", color: "white" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "white", pb: 2 }}
              >
                Verify your mobile number
              </Typography>
              <Typography component="p" variant="body2">
              Enter the code on the page and click Verify
              </Typography>
            </Box>
            <center>
              <img
                src={'https://assets.magedelight.com/media/catalog/product/o/t/otp-notification.png'}
                className="w-75 mb-3"
                alt={"welcome image"}
                loading="lazy"
              />
            </center>
          </Grid>
          <Grid
              item
              sm={8}
              component={Paper}
              elevation={6}
              sx={{ backgroundColor: "grey", color: "white" }}
            >
              <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ color: "black", pb: 2,textAlign:"center" }}
                >
                  OTP verification
                </Typography>

                <Typography
                  component="p"
                  variant="tbody"
                  sx={{ color: "black", pb: 2,textAlign:"center",textTransform:"lowercase" }}
                >
                  {/* {user.data.mobile} */}
                  we have send verification code to <b>+91 {registerInfo.mobile}</b>
                </Typography>
                <Grid item sm={12} component={Paper} elevation={0} square>
              <Box
                sx={{
                  my: 8,
                  mx: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                   
                }}
              >
                 {loading && showLoader()}
                <Box component="form" onSubmit={handleSubmit} >
            
    <div className="otpContainer">

      <input
        name="otp1"
        // type="text"
        autoComplete="off"
        className="otpInput"
         value={otp1}
        //  onkeypress={validNumber}
        onChange={handleChange}
        onKeyUp={e => inputFocus(e)}
        tabIndex="1" maxLength="1" 
        autoFocus
      />
      <input
        name="otp2"
        // type="text"
        autoComplete="off"
        className="otpInput"
        value={otp2}
        onChange={handleChange}
        // onkeypress={e=>validNumber(e)}
        onKeyUp={e => inputFocus(e)}
        tabIndex="2" maxLength="1" 

      />
      <input
        name="otp3"
        // type="text"
        autoComplete="off"
        className="otpInput"
        value={otp3}
                // onkeypress={e=>validNumber(e)}
        onKeyUp={e => inputFocus(e)}
        onChange={handleChange}

        tabIndex="3" maxLength="1" 

      />
      <input
        name="otp4"
        // type="text"
        autoComplete="off"
        className="otpInput"
        value={otp4}
       
        onChange={handleChange}
        onKeyUp={e => inputFocus(e)}
        tabIndex="4" maxLength="1" 
      />

   
    </div>
  
    <Box sx={{ mt: 5 }}>
                    <div className="card ">
                      <button
                        className="btn-sm btn-warning  disabled"
                        type="submit"
                        tabIndex="5"

                      >
                        Verify
                      </button>
                      <h6 className="text-center pt-3 text-muted">Don't received OTP?</h6>
                      <div className="btn-link text-center  mt-1" onClick={resendOTP}>
                        Resend OTP
                      </div>
                    </div>
                  </Box>
                  </Box>
                  </Box>
               
                  </Grid>

          
                </Box>
                </Grid>

          {/**/}
        </Grid>
      </div>{" "}
    </div>
    </div>
    </Draggable>
  </div>

  )
}

export default OtpPopup