// // ImageSlider.js
// import React from 'react';
// import SimpleImageSlider from "react-simple-image-slider";
// import "./imageSlider.css"

// const ImageSlider = ({
//   width = 896,
//   height = 504,
//   images = [],
//   showBullets = true,
//   showNavs = true,
//   loop = true,
//   autoPlay = true,
//   autoPlayDelay = 3,
// }) => {
//   return (
//     <div className='img-container-slider'>
//       <SimpleImageSlider
//       className="ImageSlider"
//         width={width}
//         height={height}
//         images={images}
//         showBullets={showBullets}
//         showNavs={showNavs}
//         loop={loop}
//         autoPlay={autoPlay}
//         autoPlayDelay={autoPlayDelay}
//       />
//     </div>
//   );
// };

// export default ImageSlider;

import React from 'react';
import "./imageSlider.css"
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ImageSlider = ({ carouselData }) => {
    return (
        <Carousel showArrows={true} className='main-slide' infiniteLoop={true} interval={2000} autoPlay>
            {carouselData.map((item, index) => (
                <div key={index}>
                    <img src={item.url} alt={`Slide ${index + 1}`} width="500px" height="500" />
                    {/* <p className="legend">{item.legend}</p> */}
                </div>
            ))}
        </Carousel>
    );
};
export default ImageSlider;

// ReactDOM.render(<ImageSlider />, document.querySelector('.demo-carousel'));

