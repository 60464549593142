import React from 'react'
import loadingGif1 from '../../assets/images/loading1.gif'
import loadingGif2 from '../../assets/images/loading2.gif'
// export const showLoading1 = () => {
//   return  <div>
//    <img src={loadingGif1} alt="loading"   />
//   </div>
// }
// export const showLoading2 = () => {
//   return  <div>
//    <img src={loadingGif2} alt="loading"  />
//   </div>
// }

export const showSpinner=()=>{
  return <div className='popup-box text-center'>
    <div className='box'>
    <h6 className=" spin"></h6>
    </div>
    </div>
}

export const showSpinner1=()=>{
  return <div className="loader-outer">
  <div className="loader">
    <span className="dot dot-1"></span>
    <span className="dot dot-2"></span>
    <span className="dot dot-3"></span>
    <span className="dot dot-4"></span>
  </div>
</div>
}
export const showLoader =()=>{
 return <div className="loader">
   <div className="loading"></div>
   <div className="loading"></div>
   <div className="loading"></div>
   <div className="loading"></div>
   <div className="loading"></div>
   <div className="loading"></div>
</div>
}