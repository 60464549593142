import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

import Chikencury from "./../../src/assets/images/Chikencury.jpg";
import palakpaneer from "./../../src/assets/images/palakpaneer.jpg";
import biriyani from "./../../src/assets/images/biriyani.jpg";

function RamProduct() {
  let [value, setValue] = useState(false); 
  let [quantity, setQuantity] = useState(1);
   

   const incrementQuantity = () => setQuantity(quantity + 1);
   let decrementQuantity = () => setQuantity(quantity - 1);

   if (quantity <= 0) {
     decrementQuantity = () => setQuantity(0) 
   }

  

  return (
    <div>
      <section className="procrdpg">
        <div className="row ">
          <h4 style={{ marginTop: "10px" }}>Items</h4>
          <div className="col-md-3">
            <div className="marchantproduct">
              <img src={Chikencury} alt="ChickenCury" className="img-fruit" />
              <h3>Chicken Cury</h3>

              <div className="properties_qty">
                <div className="propertiesprices">
                  {/* <h4 style={{ fontWeight:"bold" }}>Rs</h4> */}
                  <span className="price-inr">
                    <i className="fa fa-inr" aria-hidden="true" style={{color:"black"}}> </i>
                    <span className="amount"> 120</span>
                  </span>
                </div>
                {/* <button className="ip-add-cart" onClick={() => setValue(!value)}>Add to cart</button> */}

                <button className="ip-add-cart" >Add to cart</button>
                
                  {value? <>
                    <button
                    onClick={decrementQuantity}
                    style={{
                      width: "20px",
                      marginRight: "10px",
                      border: "0px",
                    }}
                  >
                    -
                  </button>
                  {quantity}
                  <button
                    onClick={incrementQuantity}
                    style={{ width: "20px", border: "0px", marginLeft: "10px" }}
                  >
                    +
                  </button>
                  </>: null}
                  
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RamProduct;
