// import React from 'react'
// import { useEffect } from 'react'
// import axios from "axios";
// import { API } from '../config/api'
// import ProductList from './ProductList';


// const ProductDetailsData = () => {

//   return (
//     <>
//     <div className="container">
//       <div className="card" style={{ height: '250px' }}>
//           <div className="card-body">
//             <h1>Product Details</h1>
//           </div>
//       </div>


//     </div>
//     </>


//     // <>
//     //   <h1>Heloo</h1>

//     //   <p>Heloo ehererbjeujbjreb</p>
//     //   <div className="product-container">
//     //     <div className="card bg-secondary  w-100 rounded-3 text-light  bs" style={{ height: '250px' }}>
//     //       {/* <div style={{height: '200px', width: '300px', border: '2px solid red'}}> */}
//     //       <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit perspiciatis commodi esse cupiditate alias quam odio dicta temporibus qui. Assumenda sint eaque eius, a quisquam dolore neque, quasi recusandae eum earum accusantium?</p>
//     //     </div>
//     //   </div>

//     // </>
//   )
// }
// export default ProductDetailsData


import React, { Fragment, useState, useContext } from "react";
// import "./pay1.css";
// import "./productdetailsdata.css"
// import card from "../assets/images/pranit.png";
// import R from "../assets/images/R.jpg";
// import Gpay from "../assets/images/Gpay.png";
// import phonepe from "../assets/images/phonepe.jpg";
// import axios from "axios";
// import { clearSessionOnCheckout, getCart, setLocalStorage } from "./helpers/localstorage";
import { useNavigate } from "react-router-dom";
// import { showLoader, showSpinner } from "../compenent/helpers/loading";
// import { StateContext } from "../context/ContextProvider";
// import { API } from "../config/api";
// import { height } from "@mui/system";
import noimage from "../assets/images/noimage.jpg";
import {
  // addToCart,
  deleteFromCart,
  // deleteLocalStorage,
  getCart,
} from "./helpers/localstorage";
import { 
  // showErrorMsg, 
  showSuccessMsg } from "./helpers/message";

import { useLocation } from "react-router-dom";
import { StateContext } from "../context/ContextProvider";

// import { Margin } from "@mui/icons-material";


//  type Props = {product}
export default function ProductDetail(product) {

  let navigate = useNavigate();
  const location = useLocation();
  const { cart, setCart } = useContext(StateContext);
  const [successMsg, setSuccessMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [merchantA, setMerchantA] = useState("");
  const [merchantB, setMerchantB] = useState("");
  const [popup, setPopup] = useState(false);
  const [isChangeCproduct, setIsChangeProduct] = useState([]);

  // const [selectedProduct, setSelectedProduct] = useState(location.state)

  // setSelectedProduct(location.state)

  let pdetails = location.state;
  const productDetails = pdetails.product;
  console.log(productDetails)
  var timeout;


  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    // setSuccessMsg(true);
    // clearTimeout(timeout);
    // timeout = setTimeout(function () {
    //   setSuccessMsg(false);
    // }, 1000);
    // return timeout;
  };

  const handleAddToCart = (e, product) => {
    // e.preventDefault();
    // if (merchantExist(product.merchantId)) {
    //   setCart(prevCart => {
    //     const updatedCart = [...prevCart, product];
    //     localStorage.setItem("cart", JSON.stringify(updatedCart));
    //     return updatedCart;
    //   });
    //   setSuccessMsg(true);
    //   setMsg("Product Added SuccessFully");

    //   timeout = displaySuccessMsg(setSuccessMsg, timeout);
    // } else {
    //   console.log(product);
    //   setMerchantA(
    //     cart.filter((p) => p.merchantId !== product?.merchantId)[0].firmName
    //   );
    //   setMerchantB(localStorage.getItem("firmName"));
    //   setIsChangeProduct(product);
    //   setPopup(true);
    // }
  };

  // const merchantExist = (merId) => {
  //   return cart.every(function (p) {
  //     return p.merchantId === merId;
  //   });
  // };

  console.log(isChangeCproduct, merchantA, merchantB, popup)


  //console.log(product);

  // const { search } = useLocation();  
  // const id = new URLSearchParams(search).get("id");
  // console.log(id);


  return (
    <>

      {/*
        selectedProduct.map((product, index) => (
          <div className="product-container">
            <div className="card">
                <> {product.itemName}</>
            </div>


          </div>
          
          
          
        
        ))*/}
      <>
        {/* <button style={{float: "right", marginRight: "px", border: '1px solid red'}}>back btn</button> */}
        <div style={{ display: "grid", justifyItems: "end" }}>
          <button style={{ padding: "5px 20px", margin: "20px 50px 0 0" }} onClick={() => navigate(-1)}>back </button>
        </div>
        <div className="container" style={{ paddingTop: "0px" }}>
          <form className="product-Details">
            <div className="row">
              <div className="col">
                {/*<img
              src={pdetails.product.image[0] ? pdetails.product.image[0] : noimage}
              class="card-img-top w-100 h-50 p-1 " alt="pizza in pizz" style={{ height: '25%' }}></img>
              <hr></hr>
              <h4>name:&nbsp;{pdetails.product.itemName}</h4> 
              <h4>Product MRP &nbsp;:&nbsp;&nbsp;{pdetails.product.itemPrice} </h4>
              <hr></hr>
              <h4>About the Product</h4>
              <h4></h4>
              <p>{pdetails.product.description}</p>
             <br />
             <h4>Category &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; Group Category</h4>
             <p>{pdetails.product.groupCategoryId.title}  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;veggies and fruit</p>
             <h4>classification &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; Sub classification</h4>
             <p>Pizza  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;Indian</p>
             <br />
             <h4>Brand Name &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; Net Weight</h4>
             <p>pizza &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 0</p>
             <h4>Unit per quantity &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; Unit Quantity</h4>
             <p>1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;1 pics</p>
             <br></br>
             <br></br>
             <br></br>
                  <table>
              <tr>
                <th className="th1">Month</th>
                <th className="th1">Group Category</th>
              </tr>
              <tr>
                <td>January</td>
                <td><p class="text-left">{pdetails.product.groupCategoryId.title}</p></td>
              </tr>
              <tr>
                <td>February</td>
                <td>$80</td>
              </tr>
              
                </table>*/}
                <section class="">
                  <div class="">
                    <h2 class="text-center Product-Main-Heading">Product Details</h2>
                  </div>
                  {/* {successMsg && showSuccessMsg(msg, successMsg)} */}
                  <div class="" style={{ width: '100%' }}>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12" >
                        {/* <br></br> */}
                        <div className="card" style={{ width: '100%' }}>
                          <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <ol className="carousel-indicators slidbtn">
                              <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="0"
                                className="active"
                              ></li>
                              <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="1"
                              ></li>
                              <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="2"
                              ></li>
                            </ol>
                            <div
                              id="carouselExampleIndicators"
                              className="carousel slide"
                              data-bs-ride="carousel"
                            >
                              <div className="carousel-indicators">
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleIndicators"
                                  data-bs-slide-to="0"
                                  className="active"
                                  aria-current="true"
                                  aria-label="Slide 1"
                                ></button>
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleIndicators"
                                  data-bs-slide-to="1"
                                  aria-label="Slide 2"
                                ></button>
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleIndicators"
                                  data-bs-slide-to="2"
                                  aria-label="Slide 3"
                                ></button>
                              </div>
                              <div className="carousel-inner">
                                <div className="carousel-item active">
                                  <img
                                    className="img1"
                                    // src="/assets/images/Banners/room1.jpg"
                                    src={productDetails.image[0] ? productDetails.image[0] : noimage}
                                    // width="900px"
                                    width="900px"
                                    height="380px"
                                    alt="First slide" />
                                </div>
                                <div className="carousel-item">
                                  <img
                                    className="img1"
                                    // src="/assets/images/Banners/room2.jpg"
                                    src={productDetails.image[0] ? productDetails.image[0] : noimage}
                                    width="900px"
                                    height="380px"
                                    alt="First slide" />
                                </div>
                                <div className="carousel-item">
                                  <img
                                    className="img1"
                                    // src="/assets/images/Banners/room4.jpg"
                                    src={productDetails.image[0] ? productDetails.image[0] : noimage}
                                    width="900px"
                                    height="380px"
                                    alt="First slide" />
                                </div>
                              </div>{" "}

                              <button
                                className="carousel-control-prev"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide="prev"
                              >
                                <span className="fa fa-angle-left" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button

                                className="carousel-control-next"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide="next"
                              >
                                <span className="fa fa-angle-right" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <img className="img12"
                          src={productDetails.image[0] ? pdetails.product.image[0] : noimage}
                          class="card-img-top w-100 h-50 p-1 " alt="pizza in pizz" > 
                          
                        </img> */}
                        <table>
                          <tr>
                            <td><h4 className="prduct-head-item-size">Category</h4><p ><b className="product-description">{productDetails?.categoryId?.title}</b></p></td>
                            <td><h4 class="text-left prduct-head-item-size">Group Category</h4><p class="text-left product-description">{productDetails?.groupCategoryId?.title}</p></td>
                          </tr>
                        </table>
                      </div>
                      {console.log(productDetails?.itemName, "name print")}
                      <div class="col-lg-6 col-md-6 col-12">
                        <h3 className="prduct-head-item-size">{productDetails?.itemName}</h3>
                        <h4 className="prduct-head-item-size">Product MRP &nbsp;:&nbsp;&nbsp;
                          <span className="product-price">₹{productDetails?.itemPrice}</span>
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          &nbsp;
                          {console.log(cart)}
                          {productDetails?.status === false
                            ? (<span style={{ fontSize: "14px", color: "#475f7b" }}>
                              Product is not available
                            </span>)
                            : cart.some(
                              (p) => p._id === productDetails?._id && p.count > 0
                            ) ?
                              (
                                <button
                                  className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                                  style={{
                                    borderRadius: "5px",
                                    color: "#003C7E",
                                    border: "none",
                                    background: "none",
                                  }}
                                  onClick={() => {
                                    setSuccessMsg(true);
                                    setMsg("Product Removed SuccessFully");

                                    timeout = displaySuccessMsg(
                                      setSuccessMsg,
                                      timeout
                                    );


                                    deleteFromCart(productDetails);
                                    setCart(getCart());
                                  }}
                                >
                                  Remove
                                </button>
                              ) : (
                                <button
                                  className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                                  style={{
                                    borderRadius: "5px",
                                    color: "#003C7E",
                                    border: "none",
                                    background: "none",
                                  }}
                                  onClick={(e) => handleAddToCart(e, productDetails)}
                                >
                                  Add
                                </button>
                              )
                          }
                        </h4>

                        <hr></hr>
                        <h4 className="prduct-head-item-size">About the Product</h4>
                        <p className="product-description">{productDetails?.description}</p>
                        <table>
                          <tr>
                            <td><h4 className="prduct-head-item-size">Classification</h4><p ><b className="product-description">{productDetails?.classificationId?.title}</b></p></td>
                            <td><h4 class="text-left prduct-head-item-size">Sub classification</h4><p class="text-left product-description">{productDetails.subClassificationId?.title}</p></td>
                          </tr>
                          <tr>
                            <td><h4 className="prduct-head-item-size">Brand Name</h4><p ><b className="product-description">{productDetails?.brandNameId?.title}</b></p></td>
                            <td><h4 class="text-left prduct-head-item-size">Net Weight</h4><p class="text-left product-description">{productDetails?.netWeight}</p></td>
                          </tr>
                          <tr>
                            <td><h4 className="prduct-head-item-size">Unit Per Quantity</h4><p ><b className="product-description">{productDetails?.unitPerQuantity}</b></p></td>
                            <td><h4 class="text-left prduct-head-item-size">Unit Quantity</h4><p class="text-left product-description">{productDetails?.unitQuantityId?.title}</p></td>
                          </tr>
                        </table>

                      </div>

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </form>
        </div>

      </>
    </>
  );
}