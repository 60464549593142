import React, { Fragment } from "react";
import "./about.css";
import aboutUs from "../../assets/images/booking.jpg";
import OurMission from "../../assets/images/cars.jpg";
import WhyChooseUs from "../../assets/images/hotelbook.jpg";


function About() {
  return (
    <Fragment>
      <main className="main-about-container">
        {/* <section className=""> */}
        {/* <div class="about"><img src="assets/images/Banners/sample-1.jpg" width="100%" /></div> */}
        {/* </section> */}
        <section className="about_us_container">
          <div className="about_img">
            <img src={aboutUs} alt="aboutUs" className="about_us_image" />
          </div>
          <div className="about_us_text">
            <h3 className="About_us_main_heading">About Us</h3>
            <p className="about_us_content pra-about-text">
              We believe there is a better way to do marketing. A more valuable
              less invasive way where customers are earned rather than bought.
              We're obsessively passionate about it, and our mission is to help
              people achieve it. We believe the best way deliver a great user
              experience is by deeply understanding what people want & love.
              Then deliver the best quality product. That's what makes users
              happy & loyal. Our app strives to deliver the tools & support that
              helps to deliver great experience. We want our customer to be
              happy, so then our customer are happy & that makes us happy.
            </p>
          </div>
        </section>

        {/* sec */}
        <section className="about_us_container">
          <div className="about_us_text">
            <h3 className="About_us_main_heading">Our Mission</h3>
            <p className="about_us_content ">
              We strive to be your one-stop shop for everything you need,
              offering a carefully curated selection of products that cater to
              every aspect of your lifestyle. Our mission is to simplify your
              shopping journey, making it more convenient, affordable, and
              exciting. We listen to our customers, continuously improve, and
              strive to exceed your expectations every time you shop with us.
              Whether you're discovering new products or getting your favorite
              essentials, we’re here to make your shopping experience easy and
              enjoyable.
            </p>
          </div>
          <div className="about_img">
            <img
              src={OurMission}
              alt="OurMission"
              className="about_us_image"
            />
          </div>
        </section>

        {/* third */}
        <section className="about_us_container">
        <div className="about_img">
            <img
              src={WhyChooseUs}
              alt="WhyChooseUs"
              className="about_us_image"
            />
          </div>
          <div className="about_us_text">
            <h3 className="About_us_main_heading">Why Choose Us ?</h3>
            <p className="about_us_content boldText-para">
            <h6>
            <b className="boldText">⦿ Wide Selection:</b> From fashion to home décor, electronics to beauty, we’ve got it all.
            </h6>
          <h6>
             <b className="boldText">⦿ Customer Satisfaction:</b> Our priority is your happiness, which is why we ensure a hassle-free return policy and 24/7 customer support.
             </h6>
           <b className="boldText">⦿ Fast Delivery:</b> We work with trusted logistics partners to bring your orders to you in the quickest time possible.
           <br/>
          <h6><b className="boldText">⦿ Exclusive Deals:</b> Enjoy special discounts and promotions only available at Orbit Mart.</h6>
           <br/>
            </p>
          </div>
        </section>
      </main>
    </Fragment>
  );
}

export default About;
