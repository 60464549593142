import React,{useContext,useState} from "react";
import Draggable from 'react-draggable';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { FormGroup } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate,Link as ALink } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import { useFormik } from "formik";
import { StateContext } from "../context/ContextProvider";
import { API } from "../config/api"
import axios from "axios"
import { showSuccessMsg,showErrorMsg } from "./helpers/message";
const phoneRegex = /^\+([0-9]{1,3})*\.([0-9]{5,16})$/;

const PasswordResetPopup = (props) => {
    const {currentPage,setCurrentPage,registerInfo,setRegisterInfo,setIsOpen}=useContext(StateContext)
  // const [errMsg,setErrMsg]=useState(false)
  const [errMsg,setErrMsg]=useState(false)
  const [successMsg,setSuccessMsg]=useState(false)
  const [msg,setMsg]=useState("")
         const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .required("Please enter valid Mobile number")
      .matches(phoneRegex,"Please enter valid Mobile number"),
      otp: Yup.string()
      .required("Please enter Otp")
       .min(4, "Otp must be at least 4 characters"),
      npassword: Yup.string()
      .required("Please enter New Password")
       .min(6, "Password must be at least 6 characters"),
  });
  const formik = useFormik({
    initialValues: { mobile: registerInfo.mobile,otp:"",npassword:"" },
    validationSchema: validationSchema,
    onSubmit: async (user) => {
    if(formik.values.otp==registerInfo.otp)
    {
        const data={mobileno: registerInfo.mobile,
        otp: registerInfo.otp,
        password: formik.values.npassword}
        const config={headers:{"Content-Type":"application/json"}}
        await axios.post(`${API}/auth/forgotpassword`,data,config).then(res=>{
          setMsg(res.data.message)
      
          timeout = displaySuccessMsg(setSuccessMsg, timeout);
          setTimeout(function () {
            setCurrentPage(0)
          },3000);
        
        })
        

      }
      else
      {
        // setMsg(registerInfo.otp)
        // setErrMsg(true)
        // formik.setErrors({otp:"Invalid Otp"})
        setMsg("Invalid OTP")
      
        timeout = displayErrMsg(setErrMsg, timeout);
      }
    }
      
    });
  // const showErrorMessage = (msg) => {
  //   return (
  //     <div className="alert alert-danger" role="alert">
  //  {msg}
  //   </div>
  //   )


  // }
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },2000);
    return timeout;
  }
  const displaySuccessMsg=(setSuccessMsg, timeout)=> {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    },1000);
    return timeout;
  }
  
  const resendOTP=async()=>{
    
   await axios.get(`${API}/auth/resend/otp/${registerInfo.mobile}`).then(res=>
    setRegisterInfo({otp:res.data.data,
      mobile:registerInfo.mobile}))
      setErrMsg(true)
      setMsg(registerInfo.otp)
  }
  return (
    <div className="popup-box">
      {errMsg && showErrorMsg(msg, errMsg)}
      {successMsg && showSuccessMsg(msg, successMsg)}
            <Draggable>
     <div>

    <div className="box">
      <span className="close-icon" onClick={props.handleClose}>
        x
      </span>
      <div className=" d-flex w-100">
        <Grid container component="main">
          <CssBaseline />
          <Grid
            item
            sm={4}
            component={Paper}
            elevation={6}
            sx={{ backgroundColor: "#003C7E", color: "white" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "white", pb: 2 }}
              >
                Login
              </Typography>
              <Typography component="p" variant="body2">
                
                Sign up with your mobile number to get started
              </Typography>
            </Box>
            <center>
              <img
                src={`https://remodelersuniversity.com/wp-content/uploads/2019/07/welcome-back-account-icon-450x450.png`}
                className="w-75 mb-3"
                alt={"welcome image"}
                loading="lazy"
              />
            </center>
          </Grid>

          <Grid item sm={8} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                 
              }}
            >
                 {/* {errMsg && showErrorMessage(msg)} */}
              <Box component="form" onSubmit={formik.handleSubmit}>
                <TextField
                  variant="standard"
                  id="mobile"
                  name="mobile"
                  type="text"
                  label="Enter Mobile number"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.mobile && formik.touched.mobile}
                  helperText={
                    formik.errors.mobile && formik.touched.mobile
                      ? formik.errors.mobile
                      : ""
                  }
                 
                   autoFocus
                  
             
                />
                <TextField
                sx={{marginTop: '10px'}}
                  variant="standard"
                  id="otp"
                  name="otp"
                  type="text"
                  label="Enter Otp"
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.otp && formik.touched.otp}
                  helperText={
                    formik.errors.otp && formik.touched.otp
                      ? formik.errors.otp
                      : ""
                  }

  inputProps={{maxLength: 4,
    autocomplete: 'new-otp',
    form: {
      autocomplete: 'off',
    },
    style: { textTransform:'lowercase'  }
  }}
                   autoFocus
             
                />
             
             <TextField
              sx={{marginTop: '10px'}}
                  variant="standard"
                  id="npassword"
                  name="npassword"
                  type="password"
                  label="Enter New Password"
                  value={formik.values.npassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.npassword && formik.touched.npassword}
                  helperText={
                    formik.errors.npassword && formik.touched.npassword
                      ? formik.errors.npassword
                      : ""
                  }
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                    style: { textTransform:'lowercase' }
                  }}
                  
                  
             
                />
             
                <br />

            
                 
                  
                
                
                <p className="policy">
                    By continuing, you agree to OrbitMart's{" "}
                   
                    <ALink className="lk" to="/termsandcondition" onClick={()=>setIsOpen(false)} >
                    Terms of Use 
            </ALink>{" "}
                   
                    and{" "}
                    {/* <span className="lk" onClick={(e)=>handleNewTab(e)}>Privacy Policy</span> */}
                    {/* <Link onClick={openPrivacy}  className="lk">
                      Privacy Policy
                      
                    </Link> */}
                    <ALink className="lk" to="/privacypolicy" target="_blank" onClick={()=>setIsOpen(false)} >
              Privacy Policy
            </ALink>
                    
                    {/* <a rel="noopener noreferrer" href="/privacypolicy" className="lk" target="_blank">Privacy Policy</a> */}
                    .

                  </p>
                <Box sx={{ mt: 2 }}>
                  <div className="card ">
                    <button
                      className="btn-sm btn-warning "
                      type="submit"
                    >
                      Reset Password
                    </button>
                   
                    <h6 className="text-center pt-3 text-muted">Don't received OTP?</h6>
                      <div className="btn btn-link text-center  mt-1" onClick={resendOTP}>
                        Resend OTP
                      </div>
                  </div>
                </Box>

              
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>{" "}
    </div>
    </div>
    </Draggable>
  </div>
  )
}

export default PasswordResetPopup